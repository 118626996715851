import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useDrag } from '@use-gesture/react';
import { theme } from 'assets/styles';
import { Z_INDEX } from 'constants/zIndex';
import useObserver from 'hooks/useObserver';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { customBackHandlersAtom } from 'recoil/common';
import { Color } from 'utils/getColor';

import DrawerPortal from './DrawerPortal';

export type DrawerProps = {
  /** Drawer 안의 내용 */
  children: ReactNode;
  /** true 일 때, Drawer 열림 */
  isOpen: boolean;
  /** Drawer 닫힘 함수 */
  onClose: () => void;
  /** 머릿말 텍스트 <SubTitle /> 컴포넌트 사용 */
  header?: ReactNode;
  /** Drawer가 나타날 방향 - 기본값은 "bottom" */
  direction?: 'top' | 'bottom';
  /** 핸들 숨김여부 - direction bottom일 때 보이는 게 기본 */
  hideHandle?: boolean;
  /** z-index 값 */
  zIndex?: number;
  /** 커스텀 백그라운드 컬러(ex: 일정탭 상단) */
  backgroundColor?: Color | null;
  /** 내용이 길지 않아도 내부 높이를 최대로 적용시킬 건지 */
  isHeightMax?: boolean;
  /** Drawer 콘텐츠의 커스텀 className */
  className?: string;
};

const Drawer = ({
  isOpen,
  onClose,
  header,
  direction = 'bottom',
  children,
  zIndex,
  backgroundColor,
  isHeightMax,
  hideHandle = false,
  className,
}: DrawerProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [showBorder, setShowBorder] = useState(false);
  const observerRef = useRef<HTMLDivElement>(null);
  useObserver({ ref: observerRef, show: () => setShowBorder(false), hide: () => setShowBorder(true) });

  const bind = useDrag(({ down, movement: [, my] }) => {
    if (down && my > 30) onClose();
  });

  useEffect(() => {
    const body = document.body;
    if (isOpen) body.classList.add('hidden');
    return () => body.classList.remove('hidden');
  }, [isOpen]);

  /** DOM에 없다가 생기는 경우 처음 오픈될 때 transition 되지 않는 것 방지 */
  useEffect(() => {
    requestAnimationFrame(() => {
      setIsVisible(true);
    });

    return () => {
      setIsVisible(false);
    };
  }, []);

  const hasHandle = direction === 'bottom' && !hideHandle;

  const mountedDateRef = useRef<number>(Date.now()); // 고유한 키값으로 사용하기 위해 mount된 시점 기록
  const key = `${mountedDateRef.current}`;
  const [customBackHandlers, setCustomBackHandlers] = useRecoilState(customBackHandlersAtom);

  const isBackHandlerRegistered = useMemo(
    () => customBackHandlers.some(handler => handler.key === key),
    [customBackHandlers, key],
  );

  /** 안드로이드 뒤로가기 물리키로 닫기 */
  useEffect(() => {
    // Drawer가 열리면 해당 Drawer 닫는 핸들러 추가 (+ 중복 방지)
    if (isOpen && !isBackHandlerRegistered) {
      const newHandler = { key, handler: onClose };
      setCustomBackHandlers(prev => [...prev, newHandler]);
    }
    // Drawer가 닫히면 해당 Drawer 닫는 핸들러 제거 (물리키 이외의 방법으로 닫힐 때 대비)
    else if (!isOpen) {
      setCustomBackHandlers(prev => prev.filter(handler => handler.key !== key));
    }
  }, [isBackHandlerRegistered, setCustomBackHandlers, key, isOpen, onClose]);

  return (
    <DrawerPortal>
      <Container className="drawer" isOpen={isVisible && isOpen} zIndex={zIndex}>
        <Overlay onClick={onClose} isOpen={isVisible && isOpen} />
        <DrawerWrapper
          className="drawer-wrapper"
          isOpen={isVisible && isOpen}
          direction={direction}
          bgColor={backgroundColor}
          isHeightMax={isHeightMax}
          {...(hasHandle && bind())}>
          <div>
            {hasHandle && <DragHandle />}
            {header}
          </div>
          <Content
            className={className ? `drawer-content ${className}` : 'drawer-content'}
            onClick={e => e.stopPropagation()}
            hasHeader={!!header}
            isTopDrawer={direction === 'top'}
            showBorder={showBorder}>
            <ObserverTarget className="drawer-header-observer-target" ref={observerRef} />
            {children}
          </Content>
        </DrawerWrapper>
      </Container>
    </DrawerPortal>
  );
};

type drawerTypePick = Pick<DrawerProps, 'isOpen' | 'direction' | 'zIndex' | 'isHeightMax'> & { bgColor?: Color | null };

const Container = styled.div<drawerTypePick>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ zIndex }) => zIndex || Z_INDEX.drawer};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: ${({ isOpen }) => (isOpen ? ' visibility 0s linear 0s' : ' visibility 0s linear 0.2s')};
`;

const Overlay = styled.div<{ isOpen: boolean }>`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: ${({ isOpen }) => (isOpen ? 'opacity 0.2s ease-in' : 'opacity 0.2s ease-out')};
`;

const DrawerWrapper = styled.div<drawerTypePick>`
  ${theme.flex('column', '', '')};
  position: fixed;
  width: 100%;
  max-height: 90vh;
  min-height: 100px;
  height: ${({ isHeightMax }) => (isHeightMax ? '100%' : 'auto')};
  background-color: ${({ bgColor }) => {
    if (bgColor === null) return;
    return bgColor ? theme.color[bgColor] : 'white';
  }};
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  touch-action: none;

  ${({ direction, isOpen }) => {
    switch (direction) {
      case 'top':
        return css`
          top: 0;
          transform: translateY(${isOpen ? '0' : '-100%'});
        `;
      default:
        return css`
          bottom: 0;
          transform: translateY(${isOpen ? '0' : '100%'});
          border-top-right-radius: 24px;
          border-top-left-radius: 24px;
        `;
    }
  }}
`;

const Content = styled.div<{ hasHeader: boolean; isTopDrawer: boolean; showBorder: boolean }>`
  padding: ${({ hasHeader }) => (hasHeader ? 8 : 24)}px 20px ${({ isTopDrawer }) => (isTopDrawer ? 24 : 40)}px;
  border-top: ${({ showBorder }) => (showBorder ? `1px solid ${theme.color.gray6}` : 'none')};
  overflow-y: auto;
`;

const DragHandle = styled.div`
  height: 3px;
  width: 48px;
  border-radius: 999px;
  margin: 5px auto 0;
  cursor: grab;
  background-color: ${theme.color.gray5};
`;

const ObserverTarget = styled.div`
  height: 1px;
`;

export default Drawer;
