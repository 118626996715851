import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

const usePostMember = () => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<MemberCreateParams, MemberCreateResponse>({
    method: 'post',
    url: '/api/member',
  });

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['user-ticket'],
      });
    },
  });
};

export default usePostMember;

export type MemberCreateParams = {
  profile: {
    name: string;
    gender?: string | null;
    birthday?: string | null;
    registered_at?: string;
  };
  mobile?: string;
  user_grade_id?: number | null;
  vaccination_yn?: string;
  image?: string;
  address?: {
    detail_address: string;
  };
};

export type MemberCreateResponse = {
  id: number;
};
