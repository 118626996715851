import AppListener from 'components/AppListener';
import Popup from 'components/Popup';
import PrivateRoute from 'components/PrivateRoute';
import { createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route, RouterProvider } from 'react-router-dom';
import NoLinkStudio from 'sharedComponents/NoLinkStudio';

import BookingDetail from './Booking/Detail';
import BookingGroupCopy from './Booking/Group/Form/Copy';
import BookingGroupCreate from './Booking/Group/Form/Create';
import BookingGroupUpdate from './Booking/Group/Form/Update';
import BookingGroupUpdateAll from './Booking/Group/Form/UpdateAll';
import BookingGroupSelectMember from './Booking/Group/SelectMember';
import BookingMemo from './Booking/Memo';
import BookingPrivateComplete from './Booking/Private/Complete';
import BookingPrivateCopy from './Booking/Private/Form/Copy';
import BookingPrivateCreate from './Booking/Private/Form/Create';
import BookingPrivateUpdate from './Booking/Private/Form/Update';
import BookingPrivateUpdateAll from './Booking/Private/Form/UpdateAll';
import BookingPrivateSelectMember from './Booking/Private/SelectMember';
import CounselDetail from './CounselDetail';
import CounselForm from './CounselForm';
import EtcScheduleBulkDetail from './EtcSchedule/Detail/Bulk';
import EtcScheduleIndividualDetail from './EtcSchedule/Detail/Individual';
import EtcScheduleCreate from './EtcSchedule/Form/Create';
import EtcScheduleUpdateCopy from './EtcSchedule/Form/UpdateCopy';
import ExpiredTicket from './ExpiredTicket';
import FindPassword from './FindPassword';
import Intro from './Intro';
import Join from './Join';
import Login from './Login';
import Lounge from './Lounge';
import LoungeDetail from './LoungeDetail';
import LoungeTemp from './LoungeTemp';
import Member from './Member';
import MemberDetail from './MemberDetail';
import MemberCreate from './MemberForm/Create';
import MemberUpdate from './MemberForm/Update';
import MoreDetailsAccountName from './MoreDetails/Account/ChangeName';
import MoreDetailsAccountPhoneNumber from './MoreDetails/Account/ChangePhoneNumber';
import MoreDetailsMyAccount from './MoreDetails/Account/MyAccount';
import MoreDetailsAccountPassword from './MoreDetails/Account/PasswordReset';
import MoreDetailsAccountWithdraw from './MoreDetails/Account/Withdraw';
import MoreDetails from './MoreDetails/Main';
import MoreDetailsMessageList from './MoreDetails/Message';
import MoreDetailsMessageMobileDetail from './MoreDetails/Message/Mobile/Detail';
import MoreDetailsMessageMobileForm from './MoreDetails/Message/Mobile/Form';
import MoreDetailsMessageAppPushDetail from './MoreDetails/Message/Push/Detail';
import MoreDetailsMessageAppPushForm from './MoreDetails/Message/Push/Form';
import MoreDetailsMessageTarget from './MoreDetails/Message/Targets';
import MoreDetailsNoticeDetail from './MoreDetails/Notice/Detail';
import MoreDetailsNoticeForm from './MoreDetails/Notice/Form';
import MoreDetailsNoticeList from './MoreDetails/Notice/List';
import MoreDetailsQnaDetail from './MoreDetails/Qna/Detail';
import MoreDetailsQnaList from './MoreDetails/Qna/List';
import MoreDetailReport from './MoreDetails/Report';
import MoreDetailSchedule from './MoreDetails/Schedule';
import MoreDetailsSettingsFaq from './MoreDetails/Settings/Faq';
import MoreDetailsSettingsHelp from './MoreDetails/Settings/Help';
import MoreDetailsSettings from './MoreDetails/Settings/Main';
import MoreDetailsSettingsNoticeDetail from './MoreDetails/Settings/Notice/Detail';
import MoreDetailsSettingsNoticeList from './MoreDetails/Settings/Notice/List';
import MoreDetailsSettingsPush from './MoreDetails/Settings/Push';
import MoreDetailsStaffEventScheduleUpdateDetail from './MoreDetails/Staff/Detail/EventScheduleUpdateDetail';
import MoreDetailsStaffDetail from './MoreDetails/Staff/Detail/StaffDetail';
import MoreDetailsStaffEventScheduleUpdateForm from './MoreDetails/Staff/Form/EventScheduleUpdateForm';
import MoreDetailsStaffInfoUpdateForm from './MoreDetails/Staff/Form/InfoUpdateForm';
import MoreDetailsStaffProfileUpdateForm from './MoreDetails/Staff/Form/ProfileUpdateForm';
import MoreDetailsStaffScheduleUpdateForm from './MoreDetails/Staff/Form/ScheduleUpdateForm';
import StaffCreateForm from './MoreDetails/Staff/Form/StaffCreateForm';
import MoreDetailsStaff from './MoreDetails/Staff/List';
import MoreDetailsStaffPermissionSettings from './MoreDetails/Staff/PermissionSettings';
import RoleManagement from './MoreDetails/Staff/PermissionSettings/RoleManagement';
import MoreDetailsStudio from './MoreDetails/Studio';
import Notification from './Notification';
import Onboarding from './Onboarding';
import PageNotFound from './PageNotFound';
import ProductForm from './ProductForm';
import CalendarSetting from './Schedule/CalendarSetting';
import HolidaySetting from './Schedule/HolidaySetting';
import ScheduleMain from './Schedule/ScheduleMain';
import SelectStudio from './SelectStudio';
import TicketDetail from './TicketDetail';
import UnifiedAccount from './UnifiedAccount';

const routes = [
  { isPrivate: true, path: '/', element: <Navigate to="/schedule" replace /> },
  { isPrivate: false, path: '/onboarding', element: <Onboarding /> },
  { isPrivate: false, path: '/intro', element: <Intro /> },
  { isPrivate: false, path: '/login', element: <Login /> },
  { isPrivate: false, path: '/find-password', element: <FindPassword /> },
  { isPrivate: false, path: '/join', element: <Join /> },
  { isPrivate: false, path: '/unified-account', element: <UnifiedAccount /> },
  { isPrivate: true, path: '/select-studio', element: <SelectStudio /> },
  { isPrivate: true, path: '/counsel/form', element: <CounselForm /> },
  { isPrivate: true, path: '/counsel/detail/:id', element: <CounselDetail /> },
  // 일정
  { isPrivate: false, path: '/schedule', element: <ScheduleMain /> },
  { isPrivate: true, path: '/schedule/calendar-setting', element: <CalendarSetting /> },
  { isPrivate: true, path: '/schedule/holiday-setting', element: <HolidaySetting /> },
  // 기타일정
  { isPrivate: true, path: '/schedule/etc/form/create', element: <EtcScheduleCreate /> },
  { isPrivate: true, path: '/schedule/etc/form/:id', element: <EtcScheduleUpdateCopy /> },
  { isPrivate: true, path: '/schedule/etc/detail/bulk/:id', element: <EtcScheduleBulkDetail /> },
  { isPrivate: true, path: '/schedule/etc/detail/:id', element: <EtcScheduleIndividualDetail /> },
  // 예약
  { isPrivate: false, path: '/booking/group/form/create', element: <BookingGroupCreate /> },
  { isPrivate: false, path: '/booking/group/form/copy/:id', element: <BookingGroupCopy /> },
  { isPrivate: false, path: '/booking/group/form/update-all/:id', element: <BookingGroupUpdateAll /> },
  { isPrivate: false, path: '/booking/group/form/update/:id', element: <BookingGroupUpdate /> },
  { isPrivate: true, path: '/booking/group/select-member/:id', element: <BookingGroupSelectMember /> },
  { isPrivate: true, path: '/booking/private/select-member/:id', element: <BookingPrivateSelectMember /> },
  { isPrivate: false, path: '/booking/private/form/create', element: <BookingPrivateCreate /> },
  { isPrivate: false, path: '/booking/private/form/update/:id', element: <BookingPrivateUpdate /> },
  { isPrivate: false, path: '/booking/private/form/update-all/:id', element: <BookingPrivateUpdateAll /> },
  { isPrivate: false, path: '/booking/private/form/copy/:id', element: <BookingPrivateCopy /> },
  { isPrivate: false, path: '/booking/detail/:id', element: <BookingDetail /> },
  { isPrivate: false, path: '/booking/private/complete', element: <BookingPrivateComplete /> },
  { isPrivate: false, path: '/booking/memo/:id', element: <BookingMemo /> },
  // 회원
  { isPrivate: false, path: '/member', element: <Member /> },
  { isPrivate: false, path: '/member/form/create', element: <MemberCreate /> },
  { isPrivate: false, path: '/member/form/update/:id', element: <MemberUpdate /> },
  { isPrivate: false, path: '/member/detail/:id', element: <MemberDetail /> },
  { isPrivate: false, path: '/product/form', element: <ProductForm /> },
  { isPrivate: false, path: '/product/detail/:id', element: <TicketDetail /> },
  { isPrivate: false, path: '/product/expired/:id', element: <ExpiredTicket /> },
  { isPrivate: false, path: '/notification', element: <Notification /> },
  { isPrivate: false, path: '/lounge-temp', element: <LoungeTemp /> },
  { isPrivate: false, path: '/lounge', element: <Lounge /> },
  { isPrivate: false, path: '/lounge/detail/:id', element: <LoungeDetail /> },
  // 더보기
  { isPrivate: false, path: '/more-details', element: <MoreDetails /> },
  { isPrivate: false, path: '/more-details/settings', element: <MoreDetailsSettings /> },
  { isPrivate: false, path: '/more-details/account', element: <MoreDetailsMyAccount /> },
  { isPrivate: false, path: '/more-details/account/withdraw', element: <MoreDetailsAccountWithdraw /> },
  { isPrivate: false, path: '/more-details/account/name', element: <MoreDetailsAccountName /> },
  { isPrivate: false, path: '/more-details/account/phone', element: <MoreDetailsAccountPhoneNumber /> },
  { isPrivate: false, path: '/more-details/account/password', element: <MoreDetailsAccountPassword /> },
  { isPrivate: false, path: '/more-details/settings/help', element: <MoreDetailsSettingsHelp /> },
  { isPrivate: false, path: '/more-details/settings/push', element: <MoreDetailsSettingsPush /> },
  { isPrivate: false, path: '/more-details/settings/notice', element: <MoreDetailsSettingsNoticeList /> },
  { isPrivate: false, path: '/more-details/settings/notice/:id', element: <MoreDetailsSettingsNoticeDetail /> },
  { isPrivate: false, path: '/more-details/settings/faq', element: <MoreDetailsSettingsFaq /> },
  { isPrivate: false, path: '/more-details/notice', element: <MoreDetailsNoticeList /> },
  { isPrivate: false, path: '/more-details/notice/:id', element: <MoreDetailsNoticeDetail /> },
  { isPrivate: false, path: '/more-details/notice/form', element: <MoreDetailsNoticeForm /> },
  { isPrivate: false, path: '/more-details/qna', element: <MoreDetailsQnaList /> },
  { isPrivate: false, path: '/more-details/qna/:id', element: <MoreDetailsQnaDetail /> },
  { isPrivate: false, path: '/more-details/schedule', element: <MoreDetailSchedule /> },

  { isPrivate: false, path: '/more-details/studio', element: <MoreDetailsStudio /> },
  // 강사
  { isPrivate: true, path: '/more-details/staff', element: <MoreDetailsStaff /> },
  { isPrivate: true, path: '/more-details/staff/:id', element: <MoreDetailsStaffDetail /> },
  { isPrivate: true, path: '/more-details/staff/create-form', element: <StaffCreateForm /> }, // 생성 폼
  { isPrivate: true, path: '/more-details/staff/:id/update-form/info', element: <MoreDetailsStaffInfoUpdateForm /> }, // 강사 정보 수정 폼
  { isPrivate: true, path: '/more-details/staff/:id/update-form/profile', element: <MoreDetailsStaffProfileUpdateForm /> }, // 강사 프로필 수정 폼
  { isPrivate: true, path: '/more-details/staff/:id/update-form/schedule', element: <MoreDetailsStaffScheduleUpdateForm /> }, // 강사 근무시간 수정 폼
  {
    isPrivate: true,
    path: '/more-details/staff/:id/event-schedule',
    element: <MoreDetailsStaffEventScheduleUpdateDetail />, // 특정일 근무시간 상세 페이지(단일 수정 폼)
  },
  {
    isPrivate: true,
    path: '/more-details/staff/:id/event-schedule/update-form',
    element: <MoreDetailsStaffEventScheduleUpdateForm />, // 특정일 근무시간 전체 일정 수정 폼
  },
  { isPrivate: true, path: '/more-details/staff/permission-settings', element: <MoreDetailsStaffPermissionSettings /> },
  {
    isPrivate: true,
    path: '/more-details/staff/permission-settings/role-management',
    element: <RoleManagement />,
  },

  { isPrivate: true, path: '/more-details/message/mobile/:id', element: <MoreDetailsMessageMobileDetail /> },
  { isPrivate: true, path: '/more-details/message/mobile/form', element: <MoreDetailsMessageMobileForm /> },
  { isPrivate: true, path: '/more-details/message/push/:id', element: <MoreDetailsMessageAppPushDetail /> },
  { isPrivate: true, path: '/more-details/message/push/form', element: <MoreDetailsMessageAppPushForm /> },
  { isPrivate: true, path: '/more-details/message/target', element: <MoreDetailsMessageTarget /> },

  { isPrivate: true, path: '/more-details/message', element: <MoreDetailsMessageList /> },

  // 매출
  { isPrivate: true, path: '/more-details/report', element: <MoreDetailReport /> },
  // 연동 시설 없을 때
  { isPrivate: true, path: '/no-link-studio', element: <NoLinkStudio /> },
  // not found
  { isPrivate: false, path: '*', element: <PageNotFound /> },
];

function Router() {
  return (
    <RouterProvider
      router={createBrowserRouter(
        createRoutesFromElements(
          <Route
            element={
              <>
                <AppListener />
                <Popup />
                <Outlet />
              </>
            }>
            {routes.map(route =>
              route.isPrivate ? (
                <Route path={route.path} element={<PrivateRoute>{route.element}</PrivateRoute>} key={route.path} />
              ) : (
                <Route path={route.path} element={route.element} key={route.path} />
              ),
            )}
          </Route>,
        ),
      )}
    />
  );
}

export default Router;
