import useParamsId from 'hooks/useParamsId';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import BulkDetail from './BulkDetail';

/** 기타일정 종일이면서 반복설정 하지 않은 경우의 상세 */
const EtcScheduleBulkDetail = () => {
  const currentEtcScheduleId = useParamsId();

  return (
    <ApiBoundary>
      <BulkDetail currentEtcScheduleId={currentEtcScheduleId} />
    </ApiBoundary>
  );
};

export default EtcScheduleBulkDetail;
