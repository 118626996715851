import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { color, theme } from 'assets/styles';
import Typography from 'components/Typography';
import { findKey } from 'lodash';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { calendarViewTypeAtom } from 'recoil/schedule';
import { Color } from 'utils/getColor';

type Props = {
  title: string;
  cardColor: string;
  sameTimeEvents: number;
};

const EventCounselCard = ({ ...props }: Props) => {
  const { title, cardColor, sameTimeEvents } = props;

  const calendarViewType = useRecoilValue(calendarViewTypeAtom);

  const isDayView = useMemo(() => calendarViewType === 'day', [calendarViewType]);
  const isWeekView = useMemo(() => calendarViewType === 'week', [calendarViewType]);

  /** 색상 코드로 theme 프로퍼티명 찾기 */
  const matchColor = findKey(color, colorValue => cardColor === colorValue) as Color;

  return (
    <Container cardColor={cardColor} isWeekView={isWeekView}>
      {isWeekView && sameTimeEvents > 2 ? (
        <></>
      ) : (
        <>
          <Typography size={isWeekView ? 12 : 14} weight={600} ellipsis={isDayView ? 1 : 0}>
            {title} 님
          </Typography>
          {!isWeekView && sameTimeEvents < 4 && (
            <Typography className="info-text" size={12} weight={600} lineHeight={18} textColor={matchColor} ellipsis={2}>
              상담
            </Typography>
          )}
        </>
      )}
    </Container>
  );
};

export default EventCounselCard;

const Container = styled.div<{ cardColor: string; isWeekView: boolean }>(
  ({ cardColor }) =>
    cardColor &&
    css`
      ${theme.flex('row', 'flex-start', 'space-between')};
      padding: 5px 8px;
      height: 100%;
      border-radius: 1px 4px 4px 1px;
      border-left: 2px solid ${cardColor};
      background-color: ${`${cardColor}1f`};
      overflow: hidden;
    `,

  /** 주간(week) 상담 카드 */
  ({ isWeekView }) =>
    isWeekView &&
    css`
      margin: 0px 3px 0 0;
      padding: 1px;
      border-radius: 1px 2px 2px 1px;
    `,
);
