import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button, { ButtonProps } from 'components/Button';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { Z_INDEX } from 'constants/zIndex';
import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { toastAtom } from 'recoil/toast';

export interface IToastProps {
  /** toast 텍스트 */
  message: string | null;
  /** toast type */
  type?: 'success' | 'error';
  /** toast bottom 위치 */
  bottom?: number;
  /** toast 오른쪽 버튼 */
  rightButton?: {
    textColor: ButtonProps['textColor'];
    onClick: () => void;
    text: string;
  };
}

const Toast = () => {
  const [toast, setToast] = useRecoilState(toastAtom);
  const { message, type, rightButton } = toast;

  const iconProps: React.ComponentProps<typeof Icon> = useMemo(
    () =>
      type === 'error'
        ? { name: 'errorFill', fillColor: theme.color.red1 }
        : { name: 'possibleFill', fillColor: theme.color.secondary1 },
    [type],
  );

  const handleClick = useCallback(() => {
    setToast({ message: null });
    rightButton?.onClick();
  }, [rightButton, setToast]);

  useEffect(() => {
    let timeoutId: null | ReturnType<typeof setTimeout> = null;

    if (message) timeoutId = setTimeout(() => setToast({ message: null }), 3000);

    return () => {
      if (timeoutId !== null) clearTimeout(timeoutId);
    };
  }, [message, setToast]);

  return (
    <>
      {!!message && (
        <Container {...toast}>
          {!!type && <Icon {...iconProps} />}
          <Typography span size={14} weight={500} lineHeight={18} textColor="white">
            {message}
          </Typography>
          {rightButton && (
            <Button
              textUnderline
              fontWeight={500}
              lineHeight={18}
              padding={{ left: 0, right: 4 }}
              textColor={rightButton.textColor}
              onClick={handleClick}>
              {rightButton.text}
            </Button>
          )}
        </Container>
      )}
    </>
  );
};

const Container = styled.div<Omit<IToastProps, 'contents'>>(
  ({ bottom, type }) => css`
    ${theme.flex('', 'center', '', type ? 8 : 0)};
    z-index: ${Z_INDEX.toast};
    position: absolute;
    left: 20px;
    bottom: ${bottom || 40}px;
    width: calc(100% - 40px);
    min-height: 40px;
    padding: ${type ? '8px 12px' : '11px 16px'};
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.8);

    svg {
      display: block;
    }

    .typography {
      flex: 1;
    }
  `,
);

export default Toast;
