import useParamsId from 'hooks/useParamsId';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import IndividualDetail from './IndividualDetail';

/** 기타일정 Bulk 외 상세 */
const EtcScheduleIndividualDetail = () => {
  const currentEtcScheduleId = useParamsId();

  return (
    <ApiBoundary>
      <IndividualDetail currentEtcScheduleId={currentEtcScheduleId} />
    </ApiBoundary>
  );
};

export default EtcScheduleIndividualDetail;
