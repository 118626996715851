import { theme } from 'assets/styles';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import DropDown from 'components/DropDown';
import Icon from 'components/Icon';
import useQueryString from 'hooks/useQueryString';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout, { IMainLayoutProps } from 'sharedComponents/MainLayout';

import DeleteStaffDrawer from './components/DeleteStaffDrawer';
import SkeletonStaffDetail from './components/SkeletonStaffDetail';
import StaffDetailLayout from './components/StaffDetailLayout';
import { STAFF_HEADER_TITLE } from './constants';

const MoreDetailsStaffDetail = () => {
  const navigate = useNavigate();

  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { getSearchParams } = useQueryString();
  const pageType = getSearchParams('type');
  const isFromMyInfoPage = pageType === 'my-info';

  const goUpdatePage = useCallback(() => {
    const query = pageType ? `?type=${pageType}` : '';
    navigate(`update-form/info${query}`);
  }, [navigate, pageType]);

  const dropdownContent = useMemo(() => {
    return (
      <ul>
        <li>
          <Button
            size="medium48"
            fontSize={15}
            fontWeight={500}
            textColor="gray2"
            padding={{ left: 16, right: 12 }}
            gap={36}
            rightIcon={<Icon name="writing2" size={16} fillColor={theme.color.gray2} />}
            onClick={goUpdatePage}>
            수정하기
          </Button>
        </li>
        {!isFromMyInfoPage && (
          <li>
            <Button
              size="medium48"
              fontSize={15}
              fontWeight={500}
              textColor="secondary3"
              padding={{ left: 16, right: 12 }}
              gap={36}
              rightIcon={<Icon name="delete" size={16} fillColor={theme.color.secondary3} />}
              onClick={() => setIsDrawerOpen(true)}>
              삭제하기
            </Button>
          </li>
        )}
      </ul>
    );
  }, [isFromMyInfoPage, goUpdatePage]);

  const haederOptions: IMainLayoutProps['header'] = {
    title: isFromMyInfoPage ? STAFF_HEADER_TITLE.myInfoDetail : STAFF_HEADER_TITLE.staffDetail,
    rightButton: (
      <DropDown
        isOpen={isDropDownOpen}
        content={dropdownContent}
        position="right"
        bottomSpacing={8}
        onClose={() => setIsDropDownOpen(false)}>
        <IconButton onClick={() => setIsDropDownOpen(prev => !prev)}>
          <Icon name="headerMore" fillColor={theme.color.gray1} />
        </IconButton>
      </DropDown>
    ),
    noUnderline: true,
  };

  return (
    <MainLayout header={haederOptions}>
      <DeleteStaffDrawer isDrawerOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} />
      <ApiBoundary fallback={<SkeletonStaffDetail />}>
        <StaffDetailLayout />
      </ApiBoundary>
    </MainLayout>
  );
};

export default MoreDetailsStaffDetail;
