import { AxiosError } from 'axios';
import usePatchEtcSchedule from 'hooks/service/mutations/usePatchEtcSchedule';
import usePatchEtcScheduleAll from 'hooks/service/mutations/usePatchEtcScheduleAll';
import usePatchEtcScheduleBulk from 'hooks/service/mutations/usePatchEtcScheduleBulk';
import { BookingFailResponse } from 'hooks/service/mutations/usePostBookingPrivate';
import { EtcScheduleDetailResponse } from 'hooks/service/queries/useGetEtcScheduleEtcScheduleTime';
import useErrorDialog from 'hooks/useErrorDialog';
import useQueryString from 'hooks/useQueryString';
import useToast from 'hooks/useToast';
import { isArray } from 'lodash';
import BookingRangeDate from 'pages/Booking/components/BookingRangeDate';
import BookingEndDate from 'pages/Booking/components/BookingRangeDate/BookingEndDate';
import BookingStartDate from 'pages/Booking/components/BookingRangeDate/BookingStartDate';
import { ETC_FORM_TEXT } from 'pages/EtcSchedule/constants';
import { EtcScheduleCreateFormType } from 'pages/EtcSchedule/types';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { CustomError } from 'types/errorTypes';
import { StaffType } from 'types/staffTypes';

import EtcFormMainLayout from '../components/EtcFormMainLayout';
import convertFormState from '../utils/convertFormState';
import formatEtcParams from '../utils/formatEtcParams';

type Props = {
  data: EtcScheduleDetailResponse;
  staff: StaffType;
};

const UpdateForm = ({ data, staff }: Props) => {
  const { getSearchParams } = useQueryString();
  const mode = getSearchParams('mode') as string;

  const defaultValues = useMemo(() => {
    const formValues = convertFormState(data, staff);
    switch (mode) {
      case 'update':
        return {
          ...formValues,
          endDate: data.end_on,
        };
      case 'update-all':
        return {
          ...formValues,
          startDate: data.etc_schedule.start_date,
          endDate: data.etc_schedule.end_date,
        };
      case 'update-after-all':
        return {
          ...formValues,
          endDate: data.etc_schedule.end_date,
        };
      default:
        return formValues;
    }
  }, [staff, mode, data]);

  const methods = useForm<EtcScheduleCreateFormType>({
    defaultValues,
  });

  const navigate = useNavigate();
  const { setToast } = useToast();
  const errorDialog = useErrorDialog();
  const { mutate: updateMutate } = usePatchEtcSchedule(data.id);
  const { mutate: updateBulkMutate } = usePatchEtcScheduleBulk(data.etc_schedule_id);
  const { mutate: updateAllMutate } = usePatchEtcScheduleAll(data.etc_schedule_id);

  const mutateCallback = () => {
    return {
      onSuccess: () => {
        navigate(-1);
        setToast({ type: 'success', message: ETC_FORM_TEXT.successMessage.update });
      },
      onError: (error: AxiosError<Array<BookingFailResponse> | CustomError>) => {
        const errorData = error.response?.data;
        const isArrayError = isArray(errorData);
        errorDialog.open(isArrayError ? errorData[0].message : errorData?.message);
      },
    };
  };

  const submit = (values: EtcScheduleCreateFormType) => {
    const { start, ...rest } = formatEtcParams(values);
    const { start_date, end_date, start_time, end_time, ...updateSingle } = { ...rest };

    const updateParams = {
      ...updateSingle,
      start_on: `${start_date} ${start_time || '00:00:00'}`,
      end_on: `${start_date} ${end_time || '23:59:59'}`,
    };
    switch (mode) {
      case 'update':
        return updateMutate(updateParams, mutateCallback());
      case 'update-after-all':
        return updateBulkMutate({ ...rest, start_date: data.etc_schedule.start_date, start }, mutateCallback());
      case 'update-all':
        return updateAllMutate({ ...rest }, mutateCallback());
    }
  };

  return (
    <EtcFormMainLayout {...methods} pageMode={mode === 'update' ? 'update' : 'updateAll'} onSubmit={submit}>
      {mode === 'update' && <BookingRangeDate isRange={false} />}
      {mode === 'update-after-all' && (
        <>
          <BookingStartDate />
          <BookingEndDate pageType="updateAll" />
        </>
      )}
      {mode === 'update-all' && <BookingRangeDate isRange={true} />}
    </EtcFormMainLayout>
  );
};

export default UpdateForm;
