import { theme } from 'assets/styles';
import useGetStudio from 'hooks/service/queries/useGetStudio';
import useStatusBarColor from 'hooks/useStatusBarColor';
import useToast from 'hooks/useToast';
import { ReactNode, useEffect } from 'react';

import MainLayout from '../MainLayout';
import StudioSelectionRequired from './components/StudioSelectionRequired';

type Props = {
  /**
   * 연동시설없음 토스트 뒤에 보일 UI
   * MainLayout + BottomNav 포함해야 함
   */
  noConnectedBackground: ReactNode;
};

/**
 * recoil studioId가 비었을 때 활용하는 예외상황처리
 * @description 연동된 시설 없을 때는 특정 UI 배경으로 토스트
 * @description 연동된 시설은 있지만 선택하지 않았을 경우 선택하도록 안내
 */
const StudioException = ({ noConnectedBackground }: Props) => {
  useStatusBarColor(theme.color.white);

  const { data } = useGetStudio();
  const hasStudioConnected = data?.studios.length > 0;

  const { setToast } = useToast();

  useEffect(() => {
    if (!hasStudioConnected) {
      setToast({
        message: '시설과 연동이 되어있지 않습니다.',
        bottom: 72,
      });
    }
  }, [hasStudioConnected, setToast]);

  if (!hasStudioConnected) {
    return noConnectedBackground;
  }
  return (
    <MainLayout bottomNav>
      <StudioSelectionRequired studios={data.studios} />
    </MainLayout>
  );
};

export default StudioException;
