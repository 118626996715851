/**
 * 프로필, 메모 이미지 등 이미지 업로드 hook
 * */

import { ChangeEvent, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { hasMediaPermissionSelector } from 'recoil/permission';
import { toastAtom } from 'recoil/toast';
import { postMessageToApp } from 'utils/communicateWithNative';

export type ImgUrlsType = { id: number; imgUrl: string };

const usePhoto = (ref: React.RefObject<HTMLInputElement>, isProfile?: { isProfile: boolean }) => {
  const [files, setFiles] = useState<Array<File>>([]);
  const [imgUrls, setImgUrls] = useState<Array<ImgUrlsType>>([]);

  const setToast = useSetRecoilState(toastAtom);
  const hasPermission = useRecoilValue(hasMediaPermissionSelector);

  const onAddPhoto = () => {
    if (!hasPermission) {
      postMessageToApp('REQUEST_MEDIA_PERMISSION');
      return;
    }
    ref.current?.click();
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputFiles = e.target.files;
    if (!inputFiles || !inputFiles.length) return;

    if (isProfile) {
      setFiles([inputFiles[0]]);
      setImgUrls([{ id: 0, imgUrl: URL.createObjectURL(inputFiles[0]) }]);
      return;
    }

    if (imgUrls.length + inputFiles.length > 3) {
      setToast({ type: 'error', message: '사진은 최대 3개까지만 업로드 가능합니다.' });
      return;
    }

    const tempFiles = [];
    const tempImgUrls: Array<ImgUrlsType> = [];
    for (let i = 0; i < inputFiles.length; i++) {
      tempFiles.push(inputFiles[i]);
      tempImgUrls.push({ id: i, imgUrl: URL.createObjectURL(inputFiles[i]) });
    }

    setImgUrls([...imgUrls, ...tempImgUrls]);
    setFiles([...files, ...tempFiles]);
  };

  const onRemove = (index: number) => {
    URL.revokeObjectURL(imgUrls[index].imgUrl);
    setFiles(files.filter((_, i) => i !== index));
    setImgUrls(imgUrls.filter((_, i) => i !== index));
  };

  return { files, imgUrls, onAddPhoto, onChange, onRemove, setFiles, setImgUrls };
};

export default usePhoto;
