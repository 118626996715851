import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import MainLayout from 'sharedComponents/MainLayout';

const LoungeTemp = () => {
  return (
    <MainLayout bottomNav>
      <Container>
        <Icon name="classNon" size={60} />
        <Typography className="title" size={17} weight={700}>
          COMING SOON
        </Typography>
        <Typography size={15} textColor="gray2">
          &rsquo;스튜디오메이트&rsquo; 멤버십 공간,
          <br /> 라운지가 오픈 준비중이에요.
        </Typography>
      </Container>
    </MainLayout>
  );
};

export default LoungeTemp;

const Container = styled.div`
  ${theme.flex('column', 'center')};
  width: 100%;
  height: 100%;
  text-align: center;

  .title {
    margin-top: 24px;
    margin-bottom: 8px;
  }
`;
