import { EtcScheduleDetailResponse } from 'hooks/service/queries/useGetEtcScheduleEtcScheduleTime';
import MoreButtonListDrawer from 'pages/Booking/Detail/components/MoreButtonListDrawer';
import { ETC_DETAIL_UPDATE_DRAWER_LIST } from 'pages/EtcSchedule/constants';
import { useNavigate } from 'react-router-dom';

type Props = {
  currentEtcSchedule: EtcScheduleDetailResponse;
  isOpen: boolean;
  isBulk: boolean;
  onClose: () => void;
};

const EtcUpdateListDrawer = ({ currentEtcSchedule, isBulk, isOpen, onClose }: Props) => {
  const navigate = useNavigate();

  const goEtcUpdateForm = (mode: string) => {
    const id = isBulk || mode !== 'update-all' ? currentEtcSchedule.id : currentEtcSchedule.etc_schedule_id;
    navigate(`/schedule/etc/form/${id}?mode=${mode}${isBulk || mode === 'update-all' ? '&isBulk=true' : ''}`);
  };

  return (
    <MoreButtonListDrawer
      buttonList={
        isBulk ? ETC_DETAIL_UPDATE_DRAWER_LIST.filter(item => item.value === 'update-all') : ETC_DETAIL_UPDATE_DRAWER_LIST
      }
      onClick={goEtcUpdateForm}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

export default EtcUpdateListDrawer;
