import useGetEtcScheduleEtcScheduleTime from 'hooks/service/queries/useGetEtcScheduleEtcScheduleTime';
import useCheckPermissionEtcDetail from 'hooks/useCheckPermissionEtcDetail';
import { useErrorBoundary } from 'react-error-boundary';

import DetailMain from '../components/DetailMain';

type Props = {
  currentEtcScheduleId: number;
};

const IndividualDetail = ({ currentEtcScheduleId }: Props) => {
  const { data, error } = useGetEtcScheduleEtcScheduleTime(currentEtcScheduleId);
  const { canUpdateEtcSchedule, canDeleteEtcSchedule } = useCheckPermissionEtcDetail(data.staffs);
  const { showBoundary } = useErrorBoundary();

  if (error) {
    showBoundary(error);
  }

  return (
    <DetailMain
      currentEtcScheduleId={currentEtcScheduleId}
      data={data}
      canUpdateEtcSchedule={canUpdateEtcSchedule}
      canDeleteEtcSchedule={canDeleteEtcSchedule}
    />
  );
};

export default IndividualDetail;
