import { BookingCountParams } from 'hooks/service/queries/useGetBookingCount';

import { BookingSelectMemberFormType } from '../types';

const formatLimitValidationParams = (formValues: BookingSelectMemberFormType): BookingCountParams => {
  const { selectedUserTickets, startDate, endDate } = formValues;
  const memberIds = selectedUserTickets.map(({ member }) => member.id);
  const userTicketIds = selectedUserTickets.map(({ id }) => id);

  return {
    user_ticket_ids: userTicketIds,
    member_ids: memberIds,
    start_date: startDate,
    end_date: endDate,
  };
};

export default formatLimitValidationParams;
