import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import TextFieldBase, { ITextFieldBasePropTypes } from 'components/TextFieldBase';
import React, { useCallback, useMemo, useRef, useState } from 'react';

export interface ISearchBarProps extends ITextFieldBasePropTypes {
  /** form 태그 유무 */
  isForm?: boolean;
  /** 검색창 색상 (true: gray, false: white) */
  hasInputFieldColor?: boolean;
  /** 입력한 텍스트 전체 삭제하는 검색창 오른편 clear 버튼 클릭 이벤트 */
  onClear?: () => void;
  /** 취소 버튼 클릭 이벤트 (검색 결과 초기화면으로 전환) */
  onCancel?: () => void;
  /** 검색어 요청 */
  onSubmitForm: (e: React.FormEvent<HTMLFormElement>) => void;
}

const SearchBar = React.forwardRef<HTMLInputElement, ISearchBarProps>(
  ({ id, isForm = true, hasInputFieldColor = true, onClear, onCancel, onSubmitForm, onFocus, ...props }, ref) => {
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const handleRef = (element: HTMLInputElement | null) => {
      if (typeof ref === 'function') {
        ref(element);
      }
      inputRef.current = element;
    };

    const handleClickCancel = useCallback(() => {
      onClear?.();
      onCancel?.();
    }, [onClear, onCancel]);

    const isShowCancelButton = useMemo(() => {
      if (props.value || isFocused) return true;
      return false;
    }, [props.value, isFocused]);

    return (
      <Container
        as={!isForm ? 'div' : undefined}
        className="search-bar"
        isShowCancelButton={isShowCancelButton}
        onSubmit={e => {
          e.preventDefault();
          onSubmitForm(e);
          inputRef.current?.blur();
        }}>
        <InputWrapper hasInputFieldColor={hasInputFieldColor} className="search-bar-input-wrapper">
          <Icon name="searchBold" size={16} fillColor={hasInputFieldColor ? theme.color.gray3 : theme.color.gray4} />
          <StyledInput
            className="search-bar-input"
            id={id}
            ref={handleRef}
            backgroundColor={hasInputFieldColor}
            {...props}
            onFocus={e => {
              if (onFocus) {
                onFocus(e); // 전달된 onFocus 호출
              }
              setIsFocused(true);
            }}
            onBlur={() => setIsFocused(false)}
          />
          {props.value && (
            <label
              htmlFor={id}
              onMouseDown={e => {
                e.preventDefault();
                e.currentTarget.focus();
              }}>
              <IconButton onClick={onClear}>
                <Icon name="clearFill" fillColor={theme.color.gray3} />
              </IconButton>
            </label>
          )}
        </InputWrapper>

        <Button
          id="search-bar-cancel-button"
          fontSize={15}
          textColor={hasInputFieldColor ? 'gray3' : 'white'}
          textOpacity={hasInputFieldColor ? 1 : 0.56}
          onMouseDown={handleClickCancel}>
          취소
        </Button>
      </Container>
    );
  },
);

const Container = styled.form<{ isShowCancelButton: boolean }>(
  css`
    ${theme.flex('', 'center', '')};

    .text-button {
      ${theme.flex('row', 'center', 'flex-end')};
      margin-left: 0;
      width: 0;
      word-break: keep-all;
      opacity: 0;
      transition:
        margin-left 0.2s ease,
        width 0.2s ease,
        opacity 0.2s ease;
    }
  `,

  ({ isShowCancelButton }) =>
    isShowCancelButton &&
    css`
      .text-button {
        margin-left: 12px;
        width: 26px;
        opacity: 1;
      }
    `,
);

const InputWrapper = styled.div<Pick<ISearchBarProps, 'hasInputFieldColor'>>`
  ${theme.flex('', 'center', '', 8)};
  background-color: ${({ hasInputFieldColor }) => (hasInputFieldColor ? theme.color.gray7 : theme.color.white)};
  flex: 1;
  padding: 0 16px;
  height: 40px;
  border-radius: 999px;
`;

const StyledInput = styled(TextFieldBase)`
  flex: 1;
  font-size: 1.4rem;
  font-weight: 500;

  &::placeholder {
    color: ${theme.color.gray3};
    font-size: 1.4rem;
    opacity: 0.72;
  }
`;

SearchBar.displayName = 'SearchBar';
export default SearchBar;
