import Form from 'components/Form';
import SelectBoxSquare from 'components/SelectBox/SelectBoxSquare';
import useGetStudio from 'hooks/service/queries/useGetStudio';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { staffIdAtom, studioIdAtom } from 'recoil/common';

type Props = {
  selectStudio: string;
  onChange: (e: { target: { value: string } }) => void;
};

const Studios = ({ selectStudio, onChange }: Props) => {
  const setCurrentStaffId = useSetRecoilState(staffIdAtom);
  const setStudioId = useSetRecoilState(studioIdAtom);

  const navigate = useNavigate();
  const {
    data: { studios },
  } = useGetStudio();

  useEffect(() => {
    if (!studios.length) {
      navigate('/no-link-studio');
    }
  }, [studios.length, navigate]);

  const updateCommonIds = useCallback(
    (studioId: number, staffId: number) => {
      setStudioId(studioId);
      setCurrentStaffId(staffId);
    },
    [setStudioId, setCurrentStaffId],
  );

  useEffect(() => {
    if (!studios.length) {
      navigate('/lounge');
    } else if (studios.length === 1) {
      const { id, staff_id } = studios[0];
      updateCommonIds(id, staff_id);
      navigate('/schedule');
    }
  }, [studios, navigate, updateCommonIds]);

  const confirmStudio = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (!selectStudio) return;

    const currentStudio = studios.find(studio => studio.name === selectStudio);
    if (currentStudio) {
      const { id, staff_id } = currentStudio;
      updateCommonIds(id, staff_id);
      navigate('/schedule');
    }
  };

  return (
    <Form id="select-studio-form" onSubmit={confirmStudio}>
      {studios.map(({ id, subdomain, name }) => {
        return (
          <SelectBoxSquare
            key={id}
            ref={el => el}
            type="radio"
            id={subdomain}
            name="studio"
            label={name}
            value={name}
            checked={name === selectStudio}
            onChange={onChange}
          />
        );
      })}
    </Form>
  );
};

export default Studios;
