import { useMutation } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { Response as LoginResponse } from './usePostLogin';

const usePostIntegratedAccountLogin = () => {
  const { mutationFn } = useMutationFnWithCommonParams<IntegratedAccountLoginParams, IntegratedAccountLoginResponse>({
    method: 'post',
    url: '/api/auth/integrated-account-login',
    data: {
      studio_id: undefined,
      staff_id: undefined,
    },
  });

  return useMutation({
    mutationFn,
  });
};

export default usePostIntegratedAccountLogin;

type IntegratedAccountLoginParams = {
  mobile: string;
  selected_staff_account_id: number;
};

type IntegratedAccountLoginResponse = LoginResponse;
