import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import usePostBooking, { BookingParams } from 'hooks/service/mutations/usePostBooking';
import usePostBookingGroupOver, { BookingGroupOverParams } from 'hooks/service/mutations/usePostBookingGroupOver';
import usePostBookingGroupWaiting from 'hooks/service/mutations/usePostBookingGroupWaiting';
import { BookingFailResponse } from 'hooks/service/mutations/usePostBookingPrivate';
import useGetBookingBookable from 'hooks/service/queries/useGetBookingBookable';
import useGetBookingCount from 'hooks/service/queries/useGetBookingCount';
import useErrorDialog from 'hooks/useErrorDialog';
import useToast from 'hooks/useToast';
import { isArray } from 'lodash';
import WaitingExceedDialog from 'pages/Booking/components/WaitingExceedDialog';
import { SELECT_MEMBER_FORM_TEXT } from 'pages/Booking/constants';
import CustomErrorDialog from 'pages/Booking/SelectMember/CustomErrorDialog';
import LimitValidationDialog from 'pages/Booking/SelectMember/LimitValidationDialog';
import SelectMemberForm from 'pages/Booking/SelectMember/SelectMemberForm';
import { BookingSelectMemberFormType } from 'pages/Booking/types';
import formatLimitValidationParams from 'pages/Booking/utils/formatLimitValidationParams';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { CustomError } from 'types/errorTypes';

import List from './List';

type Props = {
  currentLectureId: number;
};

const GroupSelectMemberForm = ({ currentLectureId }: Props) => {
  const { getValues, setValue } = useFormContext<BookingSelectMemberFormType>();
  const type = getValues('type');

  const [isRequestCurrentMax, setIsRequestCurrentMax] = useState(false);
  const [isRequestCountValid, setIsRequestCountValid] = useState(false);
  const [isOpenValidationDialog, setIsOpenValidationDialog] = useState(false);

  const queryClient = useQueryClient();
  const limitValidParams = formatLimitValidationParams(getValues());
  const { data: bookingCountValidation } = useGetBookingCount(isRequestCountValid, limitValidParams);
  const { data: bookableCount } = useGetBookingBookable(isRequestCurrentMax, currentLectureId);

  const navigate = useNavigate();
  const { setToast } = useToast();
  const errorDialog = useErrorDialog();
  const { mutate: bookingMutate } = usePostBooking();
  const { mutate: bookingExceedMutate } = usePostBookingGroupOver();
  const { mutate: bookingWaitingMutate } = usePostBookingGroupWaiting();

  const removeQuery = () => {
    queryClient.removeQueries({
      queryKey: ['booking/count'],
    });
  };

  const submit = () => {
    setIsRequestCountValid(true);
  };

  const mutateCallback = () => {
    return {
      onSuccess: () => {
        navigate(-1);
        setToast({ type: 'success', message: SELECT_MEMBER_FORM_TEXT.successMessage[getValues('type')] });
      },
      onError: (error: AxiosError<Array<BookingFailResponse> | CustomError>) => {
        const errorData = error.response?.data;
        const isArrayError = isArray(errorData);

        if (isArrayError) {
          // 예약 시간이 겹치는 회원일 때
          if (errorData.some(item => item.code === '10016')) {
            const duplicateUserTicketIds = errorData.filter(item => item.code === '10016').map(item => item.user_ticket_id);
            setValue('duplicateUserTicketIds', duplicateUserTicketIds);
          }
          // 이미 예약 정원이 다 찬 경우
          if (errorData.some(item => item.code === '10006')) {
            setIsRequestCurrentMax(true);
            return;
          }
          // 이미 예약 대기 정원이 다 찬 경우
          if (errorData.some(item => item.code === '10103')) {
            setIsRequestCurrentMax(true);
            return;
          }
        }
        errorDialog.open(isArrayError ? errorData[0].message : errorData?.message);
      },
    };
  };

  const bookingMembers = () => {
    const { user_ticket_ids } = limitValidParams;

    const params: BookingParams = {
      lecture_id: currentLectureId,
      user_ticket_ids,
    };
    switch (type) {
      case 'booking':
        return bookingMutate(params, mutateCallback());
      case 'exceed':
        return bookingExceedMutate({ ...params, status: 'booked' }, mutateCallback());
      case 'waitingLimit':
        return bookingExceedMutate({ ...params, status: 'booking_waiting' }, mutateCallback());
      case 'waiting':
      case 'waitingUnLimit':
        return bookingWaitingMutate(params, mutateCallback());
    }
  };

  /** 예약대기 초과예약 */
  const bookingWaitingExceed = () => {
    const { user_ticket_ids } = limitValidParams;

    const params: BookingGroupOverParams = {
      lecture_id: currentLectureId,
      user_ticket_ids,
      status: 'booking_waiting',
    };
    bookingExceedMutate(params, mutateCallback());
  };

  const getCurrentMaxCount = (max: number, current: number) => {
    return max - current > 0 ? max - current : 0;
  };

  /** 예약/예약대기 중 정원 초과 됐을 때 max 리셋 */
  const changeMaxTrainee = () => {
    if (!bookableCount || getValues('type') === 'exceed') return;
    setIsRequestCurrentMax(false);

    const { max_trainee_count, current_trainee_count, max_waiting_trainee_count, current_waiting_trainee_count } = bookableCount;
    let maxCount = getValues('maxCount');
    switch (getValues('type')) {
      case 'booking':
        maxCount = getCurrentMaxCount(max_trainee_count, current_trainee_count);
        break;
      case 'waiting':
        maxCount = getCurrentMaxCount(max_waiting_trainee_count, current_waiting_trainee_count);
    }
    setValue('maxCount', maxCount);
  };

  useEffect(() => {
    setIsRequestCountValid(false);

    /** 초과 예약 검증 걸린 것이 없어서 바로 예약하면 될 때 (반환값으로 빈배열이 옴) */
    if (isArray(bookingCountValidation)) {
      return bookingMembers();
    }

    /** 초과 예약 걸렸을 때 확인 dialog 노출 */
    if (bookingCountValidation) {
      setIsOpenValidationDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingCountValidation]);

  useEffect(() => {
    return () => {
      removeQuery();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SelectMemberForm memberList={<List currentLectureId={currentLectureId} />} onSubmit={submit} />

      {bookingCountValidation && isOpenValidationDialog && (
        <LimitValidationDialog
          bookingCountValidation={bookingCountValidation}
          onClickBooking={() => {
            setIsOpenValidationDialog(false);
            bookingMembers();
          }}
          onClose={() => {
            removeQuery();
            setIsOpenValidationDialog(false);
          }}
        />
      )}

      {type === 'booking' && isRequestCurrentMax && (
        <CustomErrorDialog
          description={SELECT_MEMBER_FORM_TEXT.dialog.error.exceed}
          onClose={() => setIsRequestCurrentMax(false)}
          onClick={changeMaxTrainee}
        />
      )}
      {type === 'waiting' && isRequestCurrentMax && bookableCount && (
        <WaitingExceedDialog
          waitingCountLimit={bookableCount.max_waiting_trainee_count}
          onClose={changeMaxTrainee}
          onClick={bookingWaitingExceed}
        />
      )}
    </>
  );
};

export default GroupSelectMemberForm;
