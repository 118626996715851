import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import { Z_INDEX } from 'constants/zIndex';
import isPastOrFutureDate from 'pages/Schedule/ScheduleMain/utils/isPastOrFutureDate';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';

type Props = {
  currentDate: Date;
  setCurrentDate: Dispatch<SetStateAction<Date>>;
};

const TodayButton = ({ currentDate, setCurrentDate }: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const isToday = isPastOrFutureDate(currentDate) === 'today';

  useEffect(() => {
    const target = containerRef.current;
    if (target) {
      const transitionEndEvent = () => {
        if (Array.from(target?.classList).includes('hidden')) {
          target.style.visibility = 'visible';
          target?.classList.remove('hidden');
        } else {
          target.style.visibility = 'hidden';
          target?.classList.add('hidden');
        }
      };
      target?.addEventListener('transitionend', transitionEndEvent, { once: true });
    }
  }, []);

  return (
    <Container ref={containerRef} className={isToday ? 'hidden' : ''}>
      <Button
        className="today-button"
        size="medium40"
        edge="circular"
        fontSize={14}
        fontWeight={700}
        textColor="primary"
        widthSize={82}
        padding={{
          left: 16,
          right: 16,
        }}
        onClick={() => setCurrentDate(new Date())}>
        오늘로 가기
      </Button>
    </Container>
  );
};

export default TodayButton;

const Container = styled.div`
  position: absolute;
  bottom: 72px;
  left: 50%;
  transform: translateX(-50%);
  background: ${theme.color.white};
  border-radius: 999px;
  box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.2);
  z-index: ${Z_INDEX.floatingButton};
  opacity: 1;
  transition: opacity 0.1s ease;

  &.hidden {
    opacity: 0;
  }

  .today-button {
    width: max-content;
  }
`;
