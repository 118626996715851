import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import CheckBox from 'components/CheckBox';
import Form from 'components/Form';
import usePostRolePermissions from 'hooks/service/mutations/usePostRolePermissions';
import useGetRolePermissions from 'hooks/service/queries/useGetRolePermissions';
import useErrorDialog from 'hooks/useErrorDialog';
import useToast from 'hooks/useToast';
import { includes, isEqual, sortBy, uniq } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { CustomError } from 'types/errorTypes';

import { CheckListType } from '../types';
import MenusLabel from './components/MenusLabel';
import SettingCheckList from './components/SettingCheckList';
import SubmitButton from './components/SubmitButton';
import { DEFAULT_PERMISSION_INDEX } from './constants';

type Props = {
  checkList: CheckListType;
};

const SettingsCheckForm = ({ checkList }: Props) => {
  const navigate = useNavigate();

  const { setToast } = useToast();
  const errorDialog = useErrorDialog();

  const { getValues } = useFormContext<{ role: string }>();

  const { data: permissionResponse } = useGetRolePermissions(Number(getValues('role')));
  const [isOpenDetails, setIsOpenDetails] = useState<number[]>([]);

  /** 해당 페이지의 모든 권한 id (각 권한의 하위항목 포함) */
  const checkListIds = checkList.flatMap(item => {
    if (!item.details.length) return item.id;
    const detailIds = item.details.map(detail => detail.id);
    return [item.id, ...detailIds];
  });

  /** 기존에 가지고 있던 권한 아이디(모든 관리 페이지) */
  const beforeIds = permissionResponse.map(permission => permission.id).slice(DEFAULT_PERMISSION_INDEX);

  /** 해당 관리 페이지에서 기존에 가지고 있던 권한 아이디 */
  const currentBeforeIds = beforeIds.filter(id => includes(checkListIds, id));

  const [checkPermissionIds, setCheckPermissionIds] = useState<number[]>(currentBeforeIds || []);

  const isDirty = !isEqual(currentBeforeIds, sortBy(checkPermissionIds));

  const isOnlySomeChecked = useMemo(() => {
    if (checkPermissionIds.length && checkListIds.length !== checkPermissionIds.length) return true;
    return false;
  }, [checkListIds, checkPermissionIds]);

  const checkedAll = (e: { target: { checked: boolean } }) => {
    if (e.target.checked) {
      const index = makeDetailsIndex();
      setCheckPermissionIds(checkListIds);
      setIsOpenDetails(index);
    } else {
      setCheckPermissionIds([]);
      setIsOpenDetails([]);
    }
  };

  const { mutate: postRolePermissionsMutate } = usePostRolePermissions({ roleId: Number(getValues('role')) });

  const submit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (!isDirty) return;

    /** 권한 아이디 1~19는 필수값으로 들어가야함.(https://tlxstudio.atlassian.net/wiki/spaces/WIKI/pages/1915027459) */
    const fixedIds = Array.from({ length: DEFAULT_PERMISSION_INDEX }, (_, i) => i + 1);

    /** 기존 가지고 있던 권한 아이디에서 해당 관리 페이지 권한 제외한 배열 */
    const defaultIds = beforeIds.filter(id => !includes(checkListIds, id));

    /** 1~19 아이디 + 기존 권한 아이디 + 변경되는 권한 아이디 */
    const params = {
      permissions: uniq([...fixedIds, ...defaultIds, ...checkPermissionIds]),
    };
    postRolePermissionsMutate(params, {
      onSuccess: () => {
        navigate(-1);
        setToast({ type: 'success', message: '시설 관리 권한이 저장되었습니다.' });
      },
      onError: (error: CustomError) => {
        errorDialog.open(error);
      },
    });
  };

  // detail를 오픈하기 위한 인덱스 배열 만들기
  const makeDetailsIndex = useCallback((): number[] => {
    return Array.from({ length: checkList.length }, (_, index) => index);
  }, [checkList]);

  // 아이콘 버튼 클릭 시 열림/닫힘 상태를 관리하는 함수
  const toggleDetail = useCallback(
    (detailIndex: number) => {
      const isIncludeIndex = isOpenDetails.includes(detailIndex);
      if (isIncludeIndex) {
        setIsOpenDetails(prev => prev.filter(index => index !== detailIndex));
      } else {
        setIsOpenDetails(prev => [...prev, detailIndex]);
      }
    },
    [isOpenDetails],
  );

  return (
    <>
      <Container id="check-submit" onSubmit={submit} isOnlySomeChecked={isOnlySomeChecked}>
        <CheckBox
          id="checked-all"
          className="checked-all"
          label={<MenusLabel label="전체선택" />}
          gap={12}
          checkBold
          onChange={checkedAll}
          checked={checkListIds.length === checkPermissionIds.length}
          iconName={isOnlySomeChecked ? 'lineBold' : 'selectCheckBold'}
          checkColor={isOnlySomeChecked || checkListIds.length === checkPermissionIds.length ? 'white' : 'gray5'}
        />
        <SettingCheckList
          checkList={checkList}
          ids={checkPermissionIds}
          setIds={(checked: boolean, checkIds: number[]) => {
            if (checked) {
              setCheckPermissionIds([...checkPermissionIds, ...checkIds]);
            } else {
              const filteredIds = checkPermissionIds.filter(id => !checkIds.includes(id));
              setCheckPermissionIds(filteredIds);
            }
          }}
          isOpenDetails={isOpenDetails}
          setIsOpenDetails={setIsOpenDetails}
          toggleDetail={toggleDetail}
        />
      </Container>
      <SubmitButton disabled={!isDirty} />
    </>
  );
};

export default SettingsCheckForm;

const Container = styled(Form)<{ isOnlySomeChecked: boolean }>`
  padding: 16px 20px;

  > .checkbox {
    margin-bottom: 24px;

    .input-span-style {
      border-color: ${({ isOnlySomeChecked }) => theme.color[isOnlySomeChecked ? 'primary' : 'gray5']};
      background-color: ${({ isOnlySomeChecked }) => theme.color[isOnlySomeChecked ? 'primary' : 'white']};
    }
  }

  .check-box-label-wrapper {
    width: 100%;
  }
`;
