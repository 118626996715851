import { atom } from 'recoil';
import localStorageEffect from 'utils/localStorageEffect';

export const staffIdAtom = atom({
  key: 'staffId',
  default: 0,
  effects: [localStorageEffect('staff_id')],
});

export const studioIdAtom = atom({
  key: 'studioId',
  default: 0,
  effects: [localStorageEffect('studio_id')],
});

export const accessTokenAtom = atom<string | null>({
  key: 'accessToken',
  default: null,
  effects: [localStorageEffect('access_token')],
});

export const customBackHandlersAtom = atom<{ handler: () => void; key: string }[]>({
  key: 'customBackHandlersAtom',
  default: [],
});
