import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import LinkButton from 'components/Button/LinkButton';
import Drawer from 'components/Drawer';
import Typography from 'components/Typography';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { currentDateAtom } from 'recoil/schedule';

import { CREATE_SCHEDULE_DRAWER } from './constants';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const CreateScheduleDrawer = ({ isOpen, onClose }: Props) => {
  const navigate = useNavigate();

  const currentDate = useRecoilValue(currentDateAtom);

  const headerText = useMemo(() => {
    const dateFormat = dayjs(currentDate).format('YYYY.MM.DD (ddd)');
    return [dateFormat, '등록할 일정을 선택하세요.'];
  }, [currentDate]);

  return (
    <Drawer headerText={headerText} isOpen={isOpen} onClose={onClose}>
      <DrawerContainer>
        {CREATE_SCHEDULE_DRAWER.map(({ title, subTitle, link }) => (
          <li key={link}>
            <LinkButton
              onClick={() => navigate(link, { state: { from: 'calendar', selectedDate: currentDate } })}
              showArrow={false}>
              <Typography className="title" size={17} weight={600}>
                {title}
              </Typography>
              <Typography textColor="gray2">{subTitle}</Typography>
            </LinkButton>
          </li>
        ))}
      </DrawerContainer>
    </Drawer>
  );
};

const DrawerContainer = styled.ul`
  ${theme.flex('column', 'flex-start', '', 8)};
  margin: 20px 0 40px;

  li {
    .link-button {
      border-bottom: 0;
    }

    .title {
      margin-bottom: 2px;
    }
  }
`;

export default CreateScheduleDrawer;
