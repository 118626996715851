import { theme } from 'assets/styles';
import LinkButton from 'components/Button/LinkButton';
import Icon from 'components/Icon';
import Drawer from 'designedComponents/Drawers';
import useGetRole from 'hooks/service/queries/useGetRole';
import { useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import CheckRadioFilterContents from 'sharedComponents/CheckRadioFilterContents';

import { StaffProfileFormType } from '../types';

const Role = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { control } = useFormContext<StaffProfileFormType>();

  const roleId = useWatch({ control, name: 'role_id' });

  const { data: roles } = useGetRole();

  const labels = useMemo(() => {
    return roles.map(({ id, display_name }) => {
      return {
        id: id.toString(),
        label: display_name,
        value: id.toString(),
      };
    });
  }, [roles]);

  const label = useMemo(() => {
    if (!roleId) return '역할 설정';
    return labels.find(({ id }) => id === roleId.toString())?.label;
  }, [labels, roleId]);

  const textColor = useMemo(() => {
    if (!roleId) return 'gray3';
    return 'gray1';
  }, [roleId]);

  return (
    <>
      <LinkButton
        gap={10}
        showArrow
        arrowBold
        arrowSize={16}
        leftIcon={<Icon name="responsible" fillColor={theme.color.gray2} />}
        textColor={textColor}
        fontWeight={500}
        onClick={() => setIsOpen(true)}>
        {label}
      </LinkButton>

      <Drawer isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <CheckRadioFilterContents name="role_id" control={control} title="역할 설정" data={labels} />
      </Drawer>
    </>
  );
};

export default Role;
