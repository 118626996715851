import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Avatar from 'components/Avatar';
import Typography from 'components/Typography';
import usePatchStaffNoticeUpdateIsChecked from 'hooks/service/mutations/usePatchStaffNoticeUpdateIsChecked';
import { CHECK_IN_TEXT, MORE_BUTTON_LIMIT } from 'pages/Notification/constants';
import { CheckInItemType } from 'pages/Notification/types';
import { useEffect, useRef, useState } from 'react';
import getImageUrl from 'utils/getImageUrl';

import MoreButton from '../MoreButton';
import NotificationDate from '../NotificationDate';

type Props = {
  checkInItem: CheckInItemType;
};

const CheckInListItem = ({ checkInItem }: Props) => {
  const isUnread = checkInItem.is_checked === 0;
  const { mutate: readCheckInMutate } = usePatchStaffNoticeUpdateIsChecked(checkInItem.id, 'checkIn');

  const [isLongMessage, setIsLongMessage] = useState(false);
  const messageRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (messageRef.current) {
      const limitHeight = MORE_BUTTON_LIMIT.line * MORE_BUTTON_LIMIT.lineHeight;
      setIsLongMessage(messageRef.current.scrollHeight > limitHeight);
    }
  }, []);

  return (
    <Container isUnread={isUnread} onClick={() => isUnread && readCheckInMutate()}>
      <CheckInWrapper>
        <Avatar size={36} imgUrl={checkInItem.avatar ? getImageUrl(checkInItem.avatar) : undefined} />
        <div>
          <Typography size={15} weight={600} className="name">
            {checkInItem.name}
            {CHECK_IN_TEXT.memberSuffix}
          </Typography>
          <Typography size={15} textColor="gray2" className="message" ref={messageRef}>
            {checkInItem.message}
          </Typography>
          <Row>
            <NotificationDate dateTime={checkInItem.enter_time} />
            {isLongMessage ? <MoreButton targetRef={messageRef} lineLimit={MORE_BUTTON_LIMIT.line} /> : null}
          </Row>
        </div>
      </CheckInWrapper>
    </Container>
  );
};

export default CheckInListItem;

const Container = styled.div<{ isUnread: boolean }>`
  ${({ isUnread }) => isUnread && `background-color: rgba(108, 133, 251, 0.08);`}
  padding: 0 20px;
`;

const CheckInWrapper = styled.div`
  ${theme.flex('row', '', '', 14)};
  text-align: left;
  border-bottom: solid 1px rgba(46, 46, 46, 0.06);

  padding: 20px 0;
  .avatar {
    flex-shrink: 0;
    margin-top: 4px;
  }

  .message {
    margin: 4px 0 8px;
  }
`;

const Row = styled.div`
  ${theme.flex('', '', 'space-between', 8)};
`;
