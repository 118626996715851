import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import useGetSfaffSudamateDetail, { SfaffSudamateDetailParams } from 'hooks/service/queries/useGetSfaffSudamateDetail';
import useParamsId from 'hooks/useParamsId';
import { useEffect, useMemo } from 'react';
import filters from 'utils/filters';
import localStorage from 'utils/localStorage';

const SudamateDetail = () => {
  const postId = useParamsId();
  const currentStaffAccountId = localStorage.get('account_id');
  const viewIds = localStorage.get('is_view_ids') || [];

  const params: SfaffSudamateDetailParams = {
    id: Number(postId),
    is_view: viewIds.includes(postId) ? 1 : 0,
    staff_id: undefined,
  };

  const { data: sudamateDetail } = useGetSfaffSudamateDetail(params);

  const { title, staff_account, created_at, view_count, contents } = sudamateDetail;

  const checkPostMe = useMemo(() => {
    return currentStaffAccountId === staff_account.id;
  }, [staff_account.id]);

  useEffect(() => {
    if (!viewIds.includes(postId)) {
      localStorage.set('is_view_ids', [...viewIds, postId]);
    }
  }, [postId]);

  return (
    <Container>
      <ContentWrapper>
        <TitleSection>
          <Typography size={17} weight={700}>
            {title}
          </Typography>
          <div className="post-info">
            <Typography textColor="gray3">{staff_account.name}</Typography>
            <Typography className="line" textColor="gray3">
              |
            </Typography>
            <Typography textColor="gray3"> {filters.dateTime(created_at)}</Typography>
            <Typography className="dot" textColor="gray3">
              ㆍ
            </Typography>
            <Typography textColor="gray3">조회 {view_count}</Typography>

            {!checkPostMe && (
              <>
                <Typography className="line" textColor="gray3">
                  |
                </Typography>
                <div className="icon-wrapper">
                  <Icon name="accuseFill" color="gray3" size={12} />
                  <Typography textColor="gray3">신고</Typography>
                </div>
              </>
            )}
          </div>
        </TitleSection>
        <ContentSection>
          <Typography size={15} lineHeight={22} tag="pre">
            {contents}
          </Typography>
        </ContentSection>
      </ContentWrapper>
      <Divider />
    </Container>
  );
};

export default SudamateDetail;

const Container = styled.div`
  padding-top: 16px;
`;

const ContentWrapper = styled.div`
  padding: 0 20px 72px;
`;

const TitleSection = styled.section`
  .post-info {
    ${theme.flex('row', 'center', '', 8)};
    padding-top: 4px;

    .line {
      font-size: 10px;
    }

    .dot {
      margin: 0 -6px;
    }

    .icon-wrapper {
      ${theme.flex('row', 'center', '', 3)};
    }
  }
`;

const ContentSection = styled.section`
  padding-top: 40px;
`;
