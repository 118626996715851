import useGetEtcScheduleEtcSchedule from 'hooks/service/queries/useGetEtcScheduleEtcSchedule';
import useCheckPermissionEtcDetail from 'hooks/useCheckPermissionEtcDetail';
import convertBulkData from 'pages/EtcSchedule/Form/utils/convertBulkData';
import { useErrorBoundary } from 'react-error-boundary';

import DetailMain from '../components/DetailMain';

type Props = {
  currentEtcScheduleId: number;
};

const BulkDetail = ({ currentEtcScheduleId }: Props) => {
  const { data, error } = useGetEtcScheduleEtcSchedule(currentEtcScheduleId);
  const { canUpdateEtcSchedule, canDeleteEtcSchedule } = useCheckPermissionEtcDetail(data.staffs);
  const { showBoundary } = useErrorBoundary();

  if (error) {
    showBoundary(error);
  }

  return (
    <DetailMain
      currentEtcScheduleId={currentEtcScheduleId}
      data={convertBulkData(data)}
      canUpdateEtcSchedule={canUpdateEtcSchedule}
      canDeleteEtcSchedule={canDeleteEtcSchedule}
    />
  );
};

export default BulkDetail;
