import { ref, string } from 'yup';

export const mobile = string()
  .matches(/^$|^[0-9]+$/, '숫자만 입력해 주세요.') // 숫자만 허용
  .matches(/^$|^01[0,1,6,7,8,9](-?\d{3,4}){2}$/, `올바른 휴대폰 번호를 입력해 주세요.`); // '01'로 시작하고 세그먼트별로 숫자 3~4자리 제한

export const mobileRequired = mobile.required('휴대폰 번호를 입력해 주세요.');

export const password = string()
  .required('비밀번호를 입력해 주세요.')
  .matches(/^$|^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,32}$/, '영문, 숫자를 포함한 8~32자로 입력해 주세요.');

export const passwordConfirmation = string()
  .required('비밀번호를 확인해 주세요.')
  .oneOf([ref('password')], '비밀번호가 일치하지 않습니다.');

export const email = string().matches(
  /^$|^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,3}$/,
  '이메일 양식을 확인해 주세요.',
);

export const confirmedEmail = string()
  .required('이메일을 확인해 주세요.')
  .oneOf([ref('email')], '이메일이 일치하지 않습니다.');

export const money = string().matches(/^$|^[0-9,]+$/, '숫자만 입력해 주세요.');

export const domain = string()
  .required('서브 도메인 주소를 입력해 주세요.')
  .matches(/^$|^[a-z|A-Z|0-9-|\s]+$/, '영문, 숫자만 입력해 주세요.');

export const name = string()
  .test('space check', '한글, 영문, 숫자, 특수문자만 입력해 주세요.', value => (value && /\s/g.test(value) ? false : true))
  .max(20, '최대 20자 이내로 입력해 주세요.');
export const teacherNameRequired = name.required('강사명을 입력해 주세요.');

export const nickName = string()
  .test('space check', '한글, 영문, 숫자만 입력해 주세요.', value => (value && /\s/g.test(value) ? false : true))
  .matches(/^$|^[가-힣ㄱ-ㅎㅏ-ㅣ|a-z|A-Z|0-9|\s]+$/, '한글, 영문, 숫자만 입력해 주세요.')
  .max(20, '최대 20자 이내로 입력해 주세요.');
export const nickNameRequired = nickName.required('닉네임을 입력해 주세요.');

/** 회원명 한글, 영문, 숫자, 띄어쓰기 가능 */
export const memberName = string()
  .matches(/^[가-힣a-zA-Z0-9\s]+$/, '한글, 영문, 숫자만 입력해 주세요.')
  .max(20, '최대 20자 이내로 입력해 주세요.');
export const memberNameRequired = memberName.required('회원명을 입력해 주세요.');

export const mobileConfirmCode = string().matches(/^$|^[0-9]+$/, '숫자만 입력해 주세요.');

export const birth = string().required('생년월일을 입력해 주세요.');

/** ex: 회원가입은 픽커가 아닌 일반 텍스트 필드 사용 */
export const birthNumber = string()
  .matches(/^$|^[0-9]+$/, '숫자만 입력해 주세요.')
  .length(6, '숫자 6자리로 입력해 주세요.')
  .required('생년월일을 입력해 주세요.');

export const ticketInactiveReason = string()
  .matches(/^$|^[가-힣ㄱ-ㅎㅏ-ㅣ|a-z|A-Z|0-9|\s]+$/, '한글, 영문, 숫자만 입력해 주세요.')
  .max(20, '최대 20자 이내로 입력해 주세요.');

export const pointUpdateReason = string().max(20, '최대 20자 이내로 입력해 주세요.');

export const maximumLength = (length: number) => string().max(length, `최대 ${length}자 이내로 입력해 주세요.`);
