import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import TwoLinedTitle from 'designedComponents/Drawers/DrawerTitle/TwoLinedTitle';
import SingleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/SingleButtonDrawer';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import BottomWrapper from './components/BottomWrapper';
import ContentWrapper from './components/ContentWrapper';

const NoLinkStudio = () => {
  const navigate = useNavigate();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <>
      <Container>
        <ContentWrapper />
        <BottomWrapper
          onClick={() => {
            setIsDrawerOpen(true);
          }}
        />
      </Container>

      <SingleButtonDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        header={<TwoLinedTitle title={['시설 미연동 시,', '대부분의 기능을 사용할 수 없습니다.']} />}
        button={{ text: '확인', onClick: () => navigate('/schedule', { replace: true }) }}>
        <Typography size={15}>계속 진행할까요?</Typography>
      </SingleButtonDrawer>
    </>
  );
};

export default NoLinkStudio;

const Container = styled.div`
  ${theme.flex()};
  height: calc(100vh - 124px);
`;
