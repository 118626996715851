import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import dayjs from 'dayjs';
import useInfinityBoardQna from 'hooks/service/queries/useInfinityBoardQna';
import useSearchKeywords from 'hooks/useSearchKeywords';
import { useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { studioIdAtom } from 'recoil/common';
import { BOARD_SORT } from 'sharedComponents/Board/constants';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import PullToRefresh from 'sharedComponents/PullToRefresh';
import filters from 'utils/filters';
import localStorage from 'utils/localStorage';

import { BASE_DATE, QNA_IDENTIFIER, QNA_LIST_TEXT, QNA_LOCAL_STORAGE_KEY, QNA_SORT_IDENTIFIER } from '../constants';
import QnaListItem from './QnaListItem';

const QnaList = () => {
  const { searchKeyword } = useSearchKeywords(QNA_IDENTIFIER);
  const { searchKeyword: sortKeyword } = useSearchKeywords(QNA_SORT_IDENTIFIER);
  const sort = sortKeyword === BOARD_SORT.asc.value ? BOARD_SORT.asc.value : BOARD_SORT.desc.value;
  const {
    data: qnas,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isFetching,
    refetch,
  } = useInfinityBoardQna(searchKeyword, sort);

  const currentStudioId = useRecoilValue(studioIdAtom);
  /** 시설별 마지막 확인 시각을 로컬스토리지에서 가져옴 */
  const qnaTimeList: Record<string, string | undefined> = useMemo(() => {
    return localStorage.get(QNA_LOCAL_STORAGE_KEY) || {};
  }, []);
  const qnaLastVisitTime = qnaTimeList[currentStudioId] ?? BASE_DATE;

  useEffect(() => {
    return () => {
      /** 시설별 마지막 확인 시각을 로컬스토리지에 저장 */
      const updatedTimeList = { ...qnaTimeList, [currentStudioId]: filters.dateDashTimeSecond() };
      localStorage.set(QNA_LOCAL_STORAGE_KEY, updatedTimeList);
    };
  }, [currentStudioId, qnaTimeList]);

  if (qnas?.length === 0)
    return (
      <Container isEmpty>
        <Typography size={15} textColor="gray2">
          {searchKeyword ? (
            <>
              <b>&ldquo;{searchKeyword}&rdquo;</b>
              {`${QNA_LIST_TEXT.noSearchResult}`}
            </>
          ) : (
            QNA_LIST_TEXT.noQna
          )}
        </Typography>
      </Container>
    );

  return (
    <PullToRefresh onRefresh={refetch} isRefetching={isFetching}>
      <InfiniteScroll hasMore={hasNextPage} isLoading={isFetchingNextPage} loadMore={fetchNextPage}>
        <Container>
          {qnas?.map(qna => <QnaListItem key={qna.id} qna={qna} isNew={dayjs(qnaLastVisitTime).isBefore(qna.updated_at)} />)}
        </Container>
      </InfiniteScroll>
    </PullToRefresh>
  );
};

export default QnaList;

const Container = styled.div<{ isEmpty?: boolean }>`
  padding: 0 20px;
  ${theme.flex('column', 'center', '')}
  justify-content: ${({ isEmpty }) => (isEmpty ? 'center' : 'flex-start')};
  min-height: 400px;
  b {
    font-weight: 600;
    color: ${theme.color.primary};
  }
`;
