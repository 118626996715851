import { yupResolver } from '@hookform/resolvers/yup';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import usePostMemberSend from 'hooks/service/mutations/usePostMemberSend';
import { MemberDetailResponse } from 'hooks/service/queries/useGetMemberDetail';
import useErrorDialog from 'hooks/useErrorDialog';
import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import { APP_LINK_TEXT } from 'pages/MemberDetail/constants';
import { AppLinkMessageFormType } from 'pages/MemberDetail/types';
import { MOBILE_MAX_LENGTH } from 'pages/MoreDetails/Message/Mobile/Form/constants';
import { FormProvider, useForm } from 'react-hook-form';
import MainLayout from 'sharedComponents/MainLayout';
import filters from 'utils/filters';
import { smsByteCheck } from 'utils/smsByteCheck';
import { object, string } from 'yup';

import AppLinkMessageForm from './AppLinkMessageForm';
import SendMessageLoading from './SendMessageLoading';

type Props = {
  member: MemberDetailResponse;
};

const yupSchema = object().shape({
  title: string().test(
    'title',
    `${filters.numberComma(MOBILE_MAX_LENGTH.title)}바이트 이내로 입력해 주세요.`,
    value => smsByteCheck(value || '') <= MOBILE_MAX_LENGTH.title,
  ),
  message: string().test(
    'message',
    `${filters.numberComma(MOBILE_MAX_LENGTH.message)}바이트 이내로 입력해 주세요.`,
    value => smsByteCheck(value || '') <= MOBILE_MAX_LENGTH.message,
  ),
});

const AppLinkMessage = ({ member }: Props) => {
  const { studio, id: memberId, name } = member;

  const methods = useForm<AppLinkMessageFormType>({
    mode: 'onChange',
    resolver: yupResolver(yupSchema),
    defaultValues: {
      isReserve: false,
      memberId,
      name,
      title: APP_LINK_TEXT.form.title,
      message: `[${studio.name}] ${APP_LINK_TEXT.form.message}`,
    },
  });

  const { setPopup } = usePopup();
  const { setToast } = useToast();
  const errorDialog = useErrorDialog();
  const { mutate: sendAppLinkMessageMutate, isPending } = usePostMemberSend();

  const sendMessage = (values: AppLinkMessageFormType) => {
    const { isReserve, reservationDate, name, memberId, ...rest } = values;

    const params = {
      ...rest,
      member_id: memberId,
      send_on: isReserve ? reservationDate : undefined,
    };

    sendAppLinkMessageMutate(params, {
      onSuccess: () => {
        const message = `1건의 메시지가 ${isReserve ? '예약' : '발송'}되었습니다`;
        setToast({ type: 'success', message });
        setPopup(null);
      },
      onError: error => {
        errorDialog.open(error);
      },
    });
  };

  return isPending ? (
    <SendMessageLoading />
  ) : (
    <MainLayout
      header={{
        title: APP_LINK_TEXT.title,
        leftButton: (
          <IconButton onClick={() => setPopup(null)}>
            <Icon name="headerClose" />
          </IconButton>
        ),
      }}>
      <FormProvider {...methods}>
        <AppLinkMessageForm onSubmit={sendMessage} />
      </FormProvider>
    </MainLayout>
  );
};

export default AppLinkMessage;
