import { useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { customBackHandlersAtom } from 'recoil/common';
import { popupAtom } from 'recoil/popup';
import { postMessageToApp } from 'utils/communicateWithNative';
import localStorage from 'utils/localStorage';

import useHistory from './useHistory';
import useToast from './useToast';

const BACK_PRESS_INTERVAL = 0.5 * 1000; // 두 번 연속의 기준 : 간격 0.5초 이내

const useHardwareBackKey = () => {
  const lastPressedTime = useRef<number>(0);
  const navigate = useNavigate();
  const { setToast } = useToast();
  const [popup, setPopup] = useRecoilState(popupAtom);
  const [customBackHandlers, setCustomBackHandlers] = useRecoilState(customBackHandlersAtom);
  const history = useHistory();

  const accessToken = localStorage.get('access_token');

  const handleBackKey = useCallback(() => {
    // 커스텀 핸들러 중 가장 최근에 등록된 핸들러 실행
    if (customBackHandlers.length) {
      const lastOne = customBackHandlers[customBackHandlers.length - 1];
      lastOne.handler();
      setCustomBackHandlers(prev => prev.slice(0, -1));
      return;
    }

    // 팝업이 열려 있다면 팝업 닫기
    if (popup) {
      setPopup(null);
      return;
    }

    const previousPage = (history.length > 2 && history[history.length - 2]) || '';
    const RestrictedPagesHavingToken = ['/login', '/intro', '/join', 'unified-account', 'select-studio'];
    // 로그인이 되어 토큰이 있는 상태로 이동할 수 없는 페이지로 가려는 경우
    const isNotMovableAfterLogin = accessToken && RestrictedPagesHavingToken.some(page => previousPage.includes(page));
    // 이전 히스토리로 갈 수 있을 경우 뒤로가기
    if (history.length > 1 && !isNotMovableAfterLogin) {
      navigate(-1);
      return;
    }

    // 뒤로가기 버튼을 빠르게 두번 누를 경우 앱 종료
    const isPressedDouble = Date.now() - lastPressedTime.current < BACK_PRESS_INTERVAL;
    if (isPressedDouble) {
      postMessageToApp('EXIT_APP');
      return;
    }

    setToast({ message: '두번 연속으로 누르면 앱이 종료됩니다.', bottom: 72 });
    lastPressedTime.current = Date.now();
  }, [customBackHandlers, popup, history, accessToken, setToast, setCustomBackHandlers, setPopup, navigate]);

  return handleBackKey;
};

export default useHardwareBackKey;
