import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import { useFormContext } from 'react-hook-form';
import FooterButton from 'sharedComponents/FooterButton';

import { SELECT_MEMBER_FORM_TEXT } from '../constants';
import { BookingSelectMemberFormType } from '../types';

const SaveButton = () => {
  const { watch } = useFormContext<BookingSelectMemberFormType>();
  const { selectedUserTickets, duplicateUserTicketIds, maxCount, type } = watch();
  const isRequesting = useIsFetching() > 0;
  const isMutating = useIsMutating() > 0;
  /** 중복 예약된 회원이 있을 때 (패밀리 수강권인 경우 제외 - ) */
  const isDuplicated = selectedUserTickets.some(({ id, is_shared }) => duplicateUserTicketIds?.includes(id) && !is_shared);
  /** 예약 회원 추가 페이지에서 선택한 회원이 max 보다 많을 때 */
  const isSelectedOver = type === 'booking' && selectedUserTickets.length > maxCount;
  /** 예약 페이지에서 max가 없을 때 */
  const hasNoMax = type === 'booking' && !maxCount;
  const disabled = isDuplicated || isRequesting || isMutating || hasNoMax || isSelectedOver || !selectedUserTickets.length;

  return (
    <FooterButton type="submit" disabled={disabled}>
      {SELECT_MEMBER_FORM_TEXT.footerButton[type]} 완료
    </FooterButton>
  );
};

export default SaveButton;
