import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import { mobileSelectedFilterAtom } from 'recoil/message';
import Filter from 'sharedComponents/Filters';

import { MOBILE_FILTER_TAB_LABELS, MOBILE_FILTERS } from '../constants';
import MobileDrawerFilterOptions from './MobileDrawerFilterOptions';
import MobileWholeFilterOptions from './MobileWholeFilterOptions';

const MobileFilter = () => {
  return (
    <MessageFilterWrapper>
      <Filter
        filterAtom={mobileSelectedFilterAtom}
        filters={MOBILE_FILTERS}
        defaultTabLabels={MOBILE_FILTER_TAB_LABELS}
        renderDrawerOptions={MobileDrawerFilterOptions}
        renderWholeFilterPopupOptions={MobileWholeFilterOptions}
      />
    </MessageFilterWrapper>
  );
};

export default MobileFilter;

export const MessageFilterWrapper = styled.div`
  padding: 16px 0;
  background-color: ${theme.color.white};
  .filter-list {
    margin: 0;
  }
`;
