import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import useInfinityMemberAssigned from 'hooks/service/queries/useInfinityMemberAssigned';
import useScrollRestoration from 'hooks/useScrollRestoration';
import useSearchKeywords from 'hooks/useSearchKeywords';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { assignedMemberSelectedFiltersAtom } from 'recoil/Member';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import MemberCard from 'sharedComponents/MemberCard';
import PullToRefresh from 'sharedComponents/PullToRefresh';

import { convertAllMemberFilterToParams } from '../utils';
import NoData from './NoData';
import Sorting from './Sorting';
import Total from './Total';

const AssignedMemberList = () => {
  const selectedFilter = useRecoilValue(assignedMemberSelectedFiltersAtom);
  const { searchKeyword } = useSearchKeywords('member');

  const {
    data: { members, total },
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isRefetching,
    refetch,
  } = useInfinityMemberAssigned({
    ...convertAllMemberFilterToParams(selectedFilter),
    search: searchKeyword,
  });

  const navigate = useNavigate();
  const goToMemberDetail = (memberId: number) => {
    navigate(`/member/detail/${memberId}`);
  };

  useScrollRestoration();

  return (
    <PullToRefresh isRefetching={isRefetching} onRefresh={refetch}>
      <TotalWrapper>
        <Total total={total} />
        <Sorting filterAtom={assignedMemberSelectedFiltersAtom} />
      </TotalWrapper>
      {total === 0 ? (
        <NoData currentTabLabel="담당회원" currentKeyword={searchKeyword} />
      ) : (
        <InfiniteScroll gap={12} hasMore={hasNextPage} isLoading={isFetchingNextPage} loadMore={fetchNextPage}>
          {members.map(member => (
            <MemberCard key={member.id} member={member} onClick={() => goToMemberDetail(member.id)} />
          ))}
        </InfiniteScroll>
      )}
    </PullToRefresh>
  );
};

export default AssignedMemberList;

const TotalWrapper = styled.div`
  ${theme.flex('row', 'center', 'space-between')}
  margin-bottom: 16px;
  padding-top: 24px;
`;
