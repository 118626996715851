import useParamsId from 'hooks/useParamsId';
import useQueryString from 'hooks/useQueryString';
import { SELECT_MEMBER_HEADER_TITLE_TEXT } from 'pages/Booking/constants';
import { BookingSelectMemberFormType, BookingSelectMemberStateType } from 'pages/Booking/types';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import MainLayout from 'sharedComponents/MainLayout';

import PrivateSelectMemberForm from './PrivateSelectMemberForm';

const BookingPrivateSelectMember = () => {
  const { state } = useLocation() as { state: BookingSelectMemberStateType };
  const currentLectureId = useParamsId();

  const { getSearchParams } = useQueryString();
  const type = getSearchParams('type') as BookingSelectMemberFormType['type'];
  const headerTitle = SELECT_MEMBER_HEADER_TITLE_TEXT[type];
  const methods = useForm<BookingSelectMemberFormType>({
    defaultValues: {
      selectedUserTickets: [],
      duplicateUserTicketIds: [],
      type,
      ...state,
    },
  });

  return (
    <MainLayout header={{ title: headerTitle }}>
      <FormProvider {...methods}>
        <PrivateSelectMemberForm currentLectureId={currentLectureId} />
      </FormProvider>
    </MainLayout>
  );
};

export default BookingPrivateSelectMember;
