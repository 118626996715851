import MobileDrawerFilterOptions from 'pages/MoreDetails/Message/Mobile/List/components/MobileDrawerFilterOptions';
import { MessageFilterWrapper } from 'pages/MoreDetails/Message/Mobile/List/components/MobileFilter';
import MobileWholeFilterOptions from 'pages/MoreDetails/Message/Mobile/List/components/MobileWholeFilterOptions';
import { appPushSelectedFilterAtom } from 'recoil/message';
import Filter from 'sharedComponents/Filters';

import { APP_PUSH_FILTER_TAB_LABELS, APP_PUSH_FILTERS } from '../constants';

const AppPushFilter = () => {
  return (
    <MessageFilterWrapper>
      <Filter
        filterAtom={appPushSelectedFilterAtom}
        filters={APP_PUSH_FILTERS}
        defaultTabLabels={APP_PUSH_FILTER_TAB_LABELS}
        renderDrawerOptions={MobileDrawerFilterOptions}
        renderWholeFilterPopupOptions={MobileWholeFilterOptions}
      />
    </MessageFilterWrapper>
  );
};

export default AppPushFilter;
