import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import useInfinityMessagePush from 'hooks/service/queries/useInfinityMessagePush';
import useSearchKeywords from 'hooks/useSearchKeywords';
import { useRecoilValue } from 'recoil';
import { appPushSelectedFilterAtom } from 'recoil/message';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import NodataTypography from 'sharedComponents/NodataTypography';
import NoSearchDataTypography from 'sharedComponents/NoSearchDataTypography';

import { MOBILE_DEFAULT_PERIOD } from '../../Mobile/List/constants';
import AppPushListItem from './components/AppPushListItem';
import { APP_PUSH_LIST_TEXT } from './constants';

const MoreDetailsMessagePushList = () => {
  const selectedFilter = useRecoilValue(appPushSelectedFilterAtom);
  const { searchKeyword } = useSearchKeywords('message');

  const period = selectedFilter?.period ?? MOBILE_DEFAULT_PERIOD;

  const {
    data: appPushes,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfinityMessagePush({
    start_date: period?.start,
    end_date: period?.end,
    search: searchKeyword,
    push_status: selectedFilter?.push_status,
  });

  if (!appPushes.length && searchKeyword)
    return (
      <Container>
        <NoSearchDataTypography keyword={searchKeyword} />
      </Container>
    );
  if (!appPushes.length) return <StyledNodata>{APP_PUSH_LIST_TEXT.empty}</StyledNodata>;

  return (
    <StyledInfiniteScroll loadMore={fetchNextPage} isLoading={isFetchingNextPage} hasMore={hasNextPage} gap={12}>
      {appPushes?.map(appPush => <AppPushListItem key={appPush.id} push={appPush} />)}
    </StyledInfiniteScroll>
  );
};

export default MoreDetailsMessagePushList;

const Container = styled.div`
  margin: 160px 0;
  ${theme.flex()};
`;

const StyledInfiniteScroll = styled(InfiniteScroll)`
  padding: 20px;
`;

const StyledNodata = styled(NodataTypography)`
  margin: 160px 0;
`;
