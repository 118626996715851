import useParamsId from 'hooks/useParamsId';
import useQueryString from 'hooks/useQueryString';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import EtcBulk from './EtcBulk';
import EtcIndividual from './EtcIndividual';

/**
 * 수정
 * - 기타 일정 1개 수정/이후 모든 수정/모든 수정
 * - 종일 일정은 모든 수정만 가능
 * 복사
 * - 기타 일정 복사는 항상 등록 디자인과 동일
 */
const EtcScheduleUpdateCopy = () => {
  const currentEtcScheduleId = useParamsId();

  const { getSearchParams } = useQueryString();
  const isBulk = getSearchParams('isBulk') as string;
  const mode = getSearchParams('mode') as string;

  return (
    <ApiBoundary>
      {isBulk ? (
        <EtcBulk currentEtcScheduleId={currentEtcScheduleId} mode={mode === 'copy' ? 'copy' : 'update'} />
      ) : (
        <EtcIndividual currentEtcScheduleId={currentEtcScheduleId} mode={mode === 'copy' ? 'copy' : 'update'} />
      )}
    </ApiBoundary>
  );
};

export default EtcScheduleUpdateCopy;
