import dayjs from 'dayjs';
import { MemberSelectedFilterType } from 'pages/Member/types';
import { MobileSelectedFilterType } from 'pages/MoreDetails/Message/Mobile/List/type';
import { AppPushSelectedFilterType } from 'pages/MoreDetails/Message/Push/List/types';
import { MessageCounselSelectedFilterType } from 'pages/MoreDetails/Message/Targets/Counsel/types';
import { atom } from 'recoil';
import filters from 'utils/filters';

export const mobileSelectedFilterAtom = atom<MobileSelectedFilterType>({
  key: 'mobileSelectedFilterAtom',
  default: {
    period: {
      type: 'month',
      start: filters.dateDash(dayjs().startOf('month')),
      end: filters.dateDash(dayjs().endOf('month')),
    },
  },
});

export const appPushSelectedFilterAtom = atom<AppPushSelectedFilterType>({
  key: 'appPushSelectedFilterAtom',
  default: {
    period: {
      type: 'month',
      start: filters.dateDash(dayjs().startOf('month')),
      end: filters.dateDash(dayjs().endOf('month')),
    },
  },
});

/** 메세지 발송대상 회원 선택, 회원페이지 전체회원 로직과 유사 */
export const messageTargetMemberSelectedFilter = atom<MemberSelectedFilterType>({
  key: 'messageTargetMemberSelectedFilter',
  default: {},
});

/** 메세지 발송대상 상담고객 선택, 회원페이지 상담고객 로직과 유사 */
export const messageTargetCounselSelectedFilter = atom<MessageCounselSelectedFilterType>({
  key: 'messageTargetCounselSelectedFilter',
  default: {},
});
