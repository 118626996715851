import { theme } from 'assets/styles';
import dayjs from 'dayjs';
import { TicketCommon } from 'hooks/service/queries/useInfinityUserTicketProduct';
import filters from 'utils/filters';

import { MainDialogStatus, TicketDropDownValueType } from './types';

export const DROPDOWN_USABLE_DATA: TicketDropDownValueType[] = [
  { value: 'upgrade', label: '업그레이드', icon: { name: 'upgrade', fillColor: theme.color.gray2 } },
  { value: 'transfer', label: '양도', icon: { name: 'give', fillColor: theme.color.gray2 } },
  {
    value: 'refund',
    label: '환불',
    icon: { name: 'refund', fillColor: theme.color.secondary3, strokeColor: theme.color.secondary3 },
  },
  { value: 'not_usable', label: '사용불가', icon: { name: 'useOff', fillColor: theme.color.secondary3 } },
];

export const DROPDOWN_NOT_USABLE_DATA: TicketDropDownValueType[] = [
  { value: 'reuse', label: '사용재개', icon: { name: 'useOn', fillColor: theme.color.gray2 } },
  { value: 'delete', label: '삭제하기', icon: { name: 'delete', fillColor: theme.color.secondary3 } },
];

export const PAYMENT_STATUS: { [key: string]: string } = {
  purchase: '발급',
  upgrade: '업그레이드',
  refund: '환불',
  extension: '연장',
  transfer: '양도',
  commission: '양도',
  tutorial: '체험',
  experience: '체험', // 기존 tutorial와의 변수명 차이로 새로 생성
  new: '신규결제',
  reNew: '재결제',
  're-take': '재결제', // 기존 reNew와의 변수명 차이로 새로 생성
  installment_payment: '미수금',
  full_payment: '미수금',
  delete: '삭제',
};

export const KIND: { [key: string]: string } = {
  count: '변경',
  create: '발급',
  created: '발급',
  deducted: '차감',
  payment: '결제',
  upgrade: '업그레이드',
  extension: '연장',
  batch: '일괄변경',
  text: '변경', // -> context 내용 그대로 출력
  installment_payment: '미수금',
  delete: '삭제',
  full_payment: '미수금',
  holding: '수강권정지',
  staff: '담당강사',
  transfer: '양도',
};

export const CONTENT_LABEL: { [key: string]: string } = {
  // 횟수 정보
  max_coupon: '전체횟수',
  remaining_coupon: '잔여횟수',
  // usable_coupon: '예약가능횟수', -> 사용자가 변경할 수 없는 항목
  remaining_cancel: '취소가능횟수',
  daily_booking_change_limit: '당일예약변경',
  booking_limit_per_week: '주간예약제한',
  booking_limit_per_month: '월간예약제한',

  //기간제 예약 취소 표시
  is_show_cancel_count: '취소가능표시',

  // 기간 정보
  availability_start_at: '이용시작일',
  expire_at: '이용종료일',
  start_holding: '정지시작일',
  end_holding: '정지종료일',

  // 사용여부 정보 (true/false)
  is_shared: '패밀리수강권',
  use_weekly_auto_coupon_balance: '자동차감',

  // 티켓타입 정보
  type: '수강권타입',

  // 결제정보
  amount: '결제금액',
  card_amount: '카드',
  installment_period: '할부개월수',
  cash_amount: '현금',
  wiretransfer_amount: '계좌이체',
  unpaid_amount: '미수금',
  settlement_at: '결제일',
  reward_point: '포인트 적립',
  point_amount: '포인트',
};

export const TYPEOF: { [key: string]: string } = {
  // 횟수 정보
  max_coupon: 'count',
  remaining_coupon: 'count',
  remaining_cancel: 'count',
  daily_booking_change_limit: 'count',
  booking_limit_per_week: 'count',
  booking_limit_per_month: 'count',

  // 기간 정보
  availability_start_at: 'date',
  expire_at: 'date',
  settlement_at: 'date',
  start_holding: 'date',
  end_holding: 'date',

  // 사용여부 정보 (true/false)
  is_shared: 'boolean',
  use_weekly_auto_coupon_balance: 'boolean',
  is_show_cancel_count: 'boolean',

  // 티켓타입 정보
  type: 'ticketType',

  // 결제정보
  amount: 'price',
  card_amount: 'price',
  installment_period: 'installment_period',
  cash_amount: 'price',
  wiretransfer_amount: 'price',
  unpaid_amount: 'price',
  reward_point: 'price',
  point_amount: 'price',
};

/** 변경이력 > 상품(판매, 대여)일 때 노출되지 않아야 할 항목 */
export const ITEM_PRODUCT_TYPE_REMOVE_KEYS: { [key in Exclude<TicketCommon['type'], 'T' | 'P'>]: string[] } = {
  S: ['max_coupon', 'remaining_coupon', 'remaining_cancel', 'expire_at', 'is_show_cancel_count'],
  RT: ['availability_start_at', 'expire_at', 'is_show_cancel_count', 'remaining_cancel'],
  RP: ['max_coupon', 'remaining_coupon', 'remaining_cancel', 'is_show_cancel_count'],
  RM: ['remaining_cancel', 'is_show_cancel_count'],
};

export const DEFAULT_HISTORY_FILTER_PARAMS = {
  selectedOption: { value: 'today', label: '오늘' },
  selectedPeriod: {
    start_date: filters.dateDash(),
    end_date: filters.dateDash(),
  },
  order_by: 'asc',
};

export const HISTORY_SORT_DATA = [
  { id: 'asc', label: '변경일 오름차순', value: 'asc' },
  { id: 'desc', label: '변경일 내림차순', value: 'desc' },
];

export const TICKET_MEMO_TEXT = {
  title: '메모',
};

export const MAIN_DIALOG_TEXT: { [key in MainDialogStatus]: { title: string; buttonText: string } } = {
  delete: {
    title: '삭제',
    buttonText: '삭제',
  },
  reuse: {
    title: '사용재개',
    buttonText: '사용재개',
  },
};

export const TICKET_HISTORY_TEXT = {
  title: '변경 이력',
  filter: {
    description: '최대 6개월까지 조회할 수 있습니다.',
    min: dayjs().subtract(6, 'month').format('YYYY-MM-DD'),
    max: 'today',
  },
};

export const TICKET_PAYMENT_TEXT = {
  title: {
    detail: '결제 정보 상세보기',
    unpaidPayment: '미수금 결제',
    unpaidUpdate: '미수금 수정',
    paymentUpdate: '결제 정보 수정',
  },
  updateSuccessMessage: '결제정보가 수정되었습니다.',
  saveSuccessMessage: '결제정보가 저장되었습니다.',
};

export const TICKET_INACTIVE_TEXT = {
  title: '사용불가 처리',
  successMessage: {
    inactive: '사용불가 처리되었습니다.',
    reuse: '사용재개 처리되었습니다.',
  },
  form: {
    footerButton: '사용불가 처리',
    textarea: {
      label: '사용불가 사유',
      placeholder: '사용불가 처리 사유를 입력해 주세요.',
    },
  },
};

export const TICKET_HOLDING_TEXT = {
  title: '정지기간 정보',
  form: {
    checkbox: '만료일 자동계산',
    tooltip: ['정지기간 만큼 수강권 만료일이 자동 연장됩니다.'],
    footerButton: {
      update: '기간변경',
      create: '수강권 정지',
    },
  },
  successMessage: {
    create: '이 정지되었습니다.',
    update: ' 정지기간이 변경되었습니다.',
    cancel: ' 정지가 취소되었습니다.',
  },
  cancel: {
    title: '수강권 정지를 취소하시겠습니까?',
    auto: '정지를 취소하면 연장된 수강기간이\n자동 복구됩니다.',
    notAuto: '수강권 정지를 취소하시겠습니까?',
  },
};

export const TICKET_UPGRADE_TEXT = {
  title: ['수강권 선택', '업그레이드 정보', '결제 정보 입력'],
  subTitle: [
    '업그레이드 수강권을 선택해 주세요.',
    '업그레이드 정보를 확인해 주세요.',
    '업그레이드 수강권의 결제 정보를 입력해 주세요.',
  ],
  dialog: {
    info: {
      title: '업그레이드 조건',
      description: [
        '같은 수업 형태(프라이빗 / 그룹)',
        '같은 수강권 형태(횟수제 / 기간제 / 차감제)',
        '같은 최대 수강인원 또는 같은 수업 구분',
        '같거나 큰 횟수 정보(전체 횟수, 기간)',
      ],
    },
  },
  successMessage: '수강권이 변경되었습니다.',
};

export const TICKET_TRANSFER_TEXT = {
  title: ['양수자 선택', '정보 입력', '결제 정보 입력', ['님에게', '을 양도하시겠습니까?']],
  subTitle: [
    '을 양도할 양수자를 선택해 주세요.',
    ['양도할', '정보를 입력해 주세요.'],
    ['양도할', '의 결제 정보를 입력해 주세요.'],
  ],
  form: {
    search: {
      placeholder: '회원명 또는 전화번호 검색',
    },
    cancelCount: {
      label: '예약취소 가능 횟수',
      noValue: '사용안함',
    },
    dailyBookingChangeLimit: {
      label: '당일 예약 변경 가능 횟수',
      noValue: '사용안함',
    },
    payment: {
      settlementDate: '양도일시',
      footerButton: '총 양도금액',
    },
  },
  successMessage: '이 양도되었습니다.',
};

export const TICKET_REFUND_TEXT = {
  title: '환불',
  cardTitle: '정보',
  form: {
    payment: {
      settlementDate: '환불일시',
      footerButton: '총 환불금액',
    },
  },
  errorMessage: {
    pointLack: '잔여 포인트가 부족합니다.',
  },
  successMessage: '이 환불되었습니다.',
  dialog: {
    title: '수강권을 환불하시겠습니까?',
    description: {
      userTicket: '예약(예약대기) 수업은 자동으로 취소됩니다.',
      item: '상품을 환불하시겠습니까?',
    },
    button: {
      negative: '취소',
      positive: '환불',
    },
  },
};
