import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import useQueryString from 'hooks/useQueryString';
import { PropsWithChildren, useCallback, useEffect } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { customBackHandlersAtom } from 'recoil/common';
import MainLayout from 'sharedComponents/MainLayout';
import ProfileForm from 'sharedComponents/ProfileField/components/Profile/ProfileForm';

import { MEMBER_FORM_TEXT } from '../constants';
import { MemberFormPageModeType, MemberFormType, ProductType } from '../types';
import getFormHeaderTitle from '../utils/getFormHeaderTitle';
import LeaveDialog from './LeaveDialog';
import ProductForm from './ProductForm';

type Props = UseFormReturn<MemberFormType> & {
  pageMode: MemberFormPageModeType;
};

const MemberFormMainLayout = ({ children, pageMode, ...methods }: PropsWithChildren<Props>) => {
  const navigate = useNavigate();
  const { getAllSearchParams } = useQueryString();
  const { type, productMode, productType, step } = getAllSearchParams();
  const headerTitle = getFormHeaderTitle({ mode: pageMode, type, productMode, productType, hasTitle: step !== '1' });

  const { setValue, getValues } = methods;

  const clickBackFromProfilePage = useCallback(() => {
    setValue('profile.name', getValues('beforeName'), { shouldDirty: true });
    setValue('image', getValues('image'), { shouldDirty: true });
    navigate(-1);
  }, [getValues, navigate, setValue]);

  const clickBack = () => {
    if (type !== 'profile') return navigate(-1);
    clickBackFromProfilePage();
  };

  const setCustomBackHandler = useSetRecoilState(customBackHandlersAtom);

  useEffect(() => {
    if (type === 'profile') {
      setCustomBackHandler(prev => [...prev, { handler: clickBackFromProfilePage, key: 'memberForm' }]);
    }
    return () => {
      setCustomBackHandler(prev => prev.filter(handler => handler.key !== 'memberForm'));
    };
  }, [clickBackFromProfilePage, setCustomBackHandler, type]);

  return (
    <MainLayout
      header={{
        title: headerTitle,
        leftButton: (
          <IconButton onClick={clickBack}>
            <Icon name="headerBack" />
          </IconButton>
        ),
      }}>
      <FormProvider {...methods}>
        {!type && children}
        {type === 'profile' && <ProfileForm label={MEMBER_FORM_TEXT.profile.label} />}
        {type === 'product' && <ProductForm productType={productType as ProductType} />}
        <LeaveDialog hasType={!!type} />
      </FormProvider>
    </MainLayout>
  );
};

export default MemberFormMainLayout;
