import { useFormContext, useWatch } from 'react-hook-form';

import { SELECT_MEMBER_FORM_TEXT } from '../constants';
import { BookingSelectMemberFormType } from '../types';
import ReserveTicketsUI from './ReserveTicketsUI';

/** 예약하려고 선택한 회원(수강권) */
const ReserveUserTickets = () => {
  const { control, getValues } = useFormContext<BookingSelectMemberFormType>();
  const selectedUserTickets = useWatch({ control, name: 'selectedUserTickets' });
  const selectedCount = selectedUserTickets.length;
  const duplicateUserTicketIds = useWatch({ control, name: 'duplicateUserTicketIds' });
  /** 예약/예약대기 가능 수가 남은 페이지에서만 max 노출  */
  const showMaxCount = getValues('type') === 'booking' || getValues('type') === 'waiting';

  return (
    <ReserveTicketsUI
      label={SELECT_MEMBER_FORM_TEXT.reservedLabel[getValues('type')]}
      selectedCount={selectedCount}
      selectedUserTickets={selectedUserTickets}
      showMaxCount={showMaxCount}
      maxCount={getValues('maxCount')}
      duplicateUserTicketIds={duplicateUserTicketIds}
    />
  );
};

export default ReserveUserTickets;
